
.warning {
    border-radius: 2px;
    @include rem(padding, 14px 10px 11px 10px);
    @include rem(margin, -2px 0 2px);

    span {
        font-family: $font-family;
        font-weight: bold;
    }

    &--success{
        background: $warning--success;
        border: $thick-border solid;
        border-color: darken($warning--success, 23%);
        color: darken($warning--success, 55%);
    }
    &--attention{
        background: $warning--attention;
        border: $thick-border solid;
        border-color: darken($warning--attention, 23%);
        color: darken($warning--attention, 55%);
    }
    &--check{
        background: $warning--check;
        border: $thick-border solid;
        border-color: darken($warning--check, 23%);
        color: darken($warning--check, 55%);
    }
    &--broken{
        background: $warning--broken;
        border: $thick-border solid;
        border-color: darken($warning--broken, 23%);
        color: darken($warning--broken, 55%);
    }
}