@tailwind base;
@tailwind components;
@tailwind utilities;

// import Bootstrap components that i'll need
@import 'styles/base/bourbon/bourbon';
@import 'styles/base/neat/neat';

@import 'styles/base/vars';
@import 'styles/base/variables';
@import 'styles/base/colors';
@import 'styles/base/font-sizes';
@import 'styles/base/mixins';
@import 'styles/base/defaults';
@import '/src/assets/default-font.css';
@import 'styles/base/buttons';
@import 'styles/base/warnings';
@import 'styles/base/icons';
@import 'styles/base/inputs';
@import 'styles/base/transitions';
@import 'styles/base/datapoints';
@import 'styles/layout/grid';
@import 'styles/layout/modals';
@import 'styles/layout/responsive-grid';
@import 'styles/layout/layout';
@import 'styles/layout/page';
@import 'styles/layout/utility-classes';
@import 'styles/layout/site-header';
@import 'styles/layout/subheader-breadcrumbs';
@import 'styles/layout/navigation';
@import 'styles/layout/scope-pod';
@import 'styles/state/state';
@import 'styles/features/library';
@import 'styles/features/cards';
// @import 'styles/modules/library_page.scss';

// @import url("https://fonts.googleapis.com/icon?family=Material+Icons");
