.mdc-dialog__container {
  z-index: 1000;
}

.white-label {
  background: #fff;
  padding-left: 15px;
  width: 70%;
  padding-right: 15px;
  md-input-container {
    width: 100%;
  }
}
.small-title-modal {
  width: 70%;
  margin-right: 0px;
}
//module for selection list!!!
.ratecard-modal {
    width: auto;
    background: transparent;
    box-shadow: none;
    .flex-block-right {
      display: inline-flex;
      align-items: center;
      justify-content: flex-end;
    }

    //state for modal on full screen!
    &--max-full-screen {
      max-height: 100%;
      min-height: 100%;
      min-width: 100%;
      max-width: 100%;
      height: 100%;
    }
    .deliverables-container {
        color: getTextColor('text-primary');
        max-width: 100%;
        @include rem(width, 1680px);
      }
}
.selection_list {
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .hidden-scroll-left {
    height: 100%;
    overflow-y: auto;
  }
  &.no-flex {
    justify-content: initial !important;
  }
  .fixed-table-height {
    height: calc(100% - 240px);
    display: flex;
    flex-direction: column;
  }
  .table-wrap {
    height: 100%;
    overflow: auto;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
    padding: 25px;
    margin-top: 20px;
    .delete-plese {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      .title {
        padding: 0px !important
      }
    }
  }
  .right-btn-small {
    button {
      padding: 0px;
      margin: 0px;
      width: 15px;
      i {
        font-size: 14px;
      }
    }
  }
  .bottom-fixed-footer-left {
    height: 115px !important;
  }
  .bottom-fixed-footer-right {
    height: 150px !important;
  }
  .no-errors {
    .md-errors-spacer {
      display: none;
      line-height: 0px
    }
    .md-input-message-animation {
      display: none;
      line-height: 0px;
    }
  }
  .bottom-24 {
    margin-top: 0px !important;
    margin-bottom: 24px !important;
  }
  .bottom-10 {
    margin-bottom: 10px !important;
    margin-top: 0px !important;
  }
  &__left {
    float: left;
  }
  &__block {
    display: block;
  }
  &__col-3 {
    width: 30%;
  }
  &__col-7 {
    width: 70%;
  }
  &__full-width {
    width: 100%;
  }
  &__right-border {
    border-right: 2px solid #E7E7E7;
  }
  &__padding-15 {
    padding: 15px;
  }
  .left-5 {
    padding-left: 5px;
  }
  h5 {
    @include rem(padding, 15px);
    &.no-margin {
      margin: 0px;
    }
    &.underline {
      border-bottom: solid 2px #000;
      padding-left: 0px;
      margin: 0px;
      padding-top: 5px;
      width: 50%;
    }
    &.margin-top {
      margin-top: 5px;
      margin-bottom: 5px;
      width: 50%;
    }
  }
  p{
    &.grey {
      color: #85888F;
      font-size: 14px;
      width: 50%;
    }
    &.margin-top {
      margin-top: 5px;
      margin-bottom: 0px;
    }
  }

  .flex-center {
    display: flex;
    align-items: center;
    i.edit-icon {
      border: 1px solid;
      font-size: 18px;
      width: 24px;
      height: 24px;
      justify-content: center;
      border-radius: 12px;
      display: inline-flex;
      color: rgb(133, 136, 143) !important;
      &:focus {
        outline: -webkit-focus-ring-color auto 0px;
      }
    }
  }
  .flex-inline {
    display: inline-flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .fixed-height {
    height: 230px;
  }
  .font-16 {
    font-size: 16px;
  }
  .center {
    text-align: center;
  }
  .right-delete-icons {
    position: absolute;
    top: 19px;
    right: 35px;
    button {
      margin: 0px;
      padding: 0px;
      width: 20px;
      height: 20px;
      i {
        font-size: 14px;
      }
    }
  }
  .no-margin {
    margin: 0px;
  }
  .content {
    @include rem(padding, 20px 0 20px 0);
  }
  .link-name {
    align-items: center;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    .link-name-flex {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .no-errors-input {
    .md-errors-spacer {
      min-height: 0px;
      display: none;
    }
  }
  .edit-icon {
    color: #D2D2D2;
    min-height: 24px;
    display: inline-flex;
    margin-right: 5px;
    align-items: center;
  }
  .padding-right {
    padding-right: 15px;
  }
  .link-number {
    color: #EE6400;
    width: 18px;
  }
  .trash {
    margin-right: 10px;
    i {
      border: solid 1px;
      font-size: 18px;
      width: 24px;
      height: 24px;
      justify-content: center;
      border-radius: 12px;
      display: inline-flex;
      color: #85888F !important;
      cursor: pointer;
    }
  }
  .flex-end {
    justify-content: flex-end !important;
  }
  .link-right {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 30%;
  }
  .menu-input {
    padding-right: 5px;
    width: 70%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 24px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px
  }
  .flex-inline-buttons {
    display: inline-flex;
    width: 35px;
    button {
      margin: 0px;
      padding: 0px;
      width: 30px;
      height: 30px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      i {
        font-size: 16px;
      }
    }
  }
  .half-block {
    width: 100%;
  }
  .button-wraper {
    margin-bottom: 23px;
  }
  ul {
    overflow-y: auto;
    max-height: calc(100vh - 350px);
    &.right-ul {
      max-height: calc(100vh - 385px) !important;
    }
    //states
    &.no-padding {
      padding: 0px;
    }
    &.no-margin {
      margin: 0px;
    }
    li {
        @include rem(padding, 15px);
        display: block;
        font-size: 14px;
        border-top: 1px solid #CFCFCF;
        min-height: 56px;
        cursor: pointer;
        position: relative;
        &:last-child {
            border-bottom: 1px solid #D0D0D0;
        }
        .material-icons {
          color: #D2D2D2;
          min-height: 24px;
          display: inline-flex;
          align-items: center;
        }
        // states;
        &.active {
            background: #EFEFEF;
            border-top: 2px solid #E7E7E7;
        }
    }
  }
  .bottom-fixed-footer {
    display: inline-flex;
    align-items: flex-end;
    height: 35px;
    padding-top: 10px;
    background-color: #fff;
    z-index: 9999999;
    @include rem(padding, 0 15px 0 15px);
    &__no-padding {
      padding: 0px;
    }
    &__flex-col {
      flex-direction: column;
      height: auto;
    }
  }
  .left_side_agency_role_config, .right_side_agency_role_config{
    width: 50%;
    float: left;
  }
  .right_side_agency_role_config{
    overflow: auto;
    padding-bottom: 20px;
    padding-right: 10px;
    height: inherit;
  }
}
//some media to improved inputs and btns on small desctops edit-ratecard-modal
@media screen and (max-width:1360px){
  .small-device-flex {
    display: inline-flex;
    align-items: center;
  }
}
@media screen and (min-width:1400px){
  .selection_list .link-name {
    width: 80%;
  }
  .selection_list .link-right {
    width: 20%;
  }
}
@media screen and (max-width:1000px) {
  .bottom-fixed-footer {
    md-input-container {
      height: 48px;
      label {
        font-size: 14px;
      }
      .md-input-message-animation {
        display: none;
        line-height: 0px;
      }
    }
    .button-wraper {
      margin-bottom: 14px;
    }
  }
}
.left-5 {
  padding-left: 5px;
}
.modal-box {
    @include background-gradient-transparent();
    cursor: default;
    min-height: 100%;
    max-height: 100%;
    width: 100%;
    z-index: 17;
    @include fixed-position;
    top: 0;
    overflow: hidden !important;
    @include rem(padding, 170px 60px 60px);

    &__deliverable {
      padding-left: 20%;
      padding-right: 20%;
      padding-top: 50px;
    }

    &__inner {
        margin: 0 auto;
        @include rem(max-width, 500px);

        &--thinner {
            @include rem(max-width, 400px);
        }
        &--white {
            background: #fff;
            @include rem(padding, 20px 40px);
            h3, h4, p {
                color: #000 !important;
            }
        }
        &--small {
            @include rem(max-width, 350px);
        }

        // form[name="addSecondPartyForm"] {
        //     md-select, md-input-container {
        //         height: 50px;
        //         background: #fff;
        //         margin-bottom: 5px;

        //         label {
        //             color: rgba(0,0,0,0.26);
        //         }
        //     }

        //     .md-errors-spacer {
        //         display: none;
        //     }

        //     .md-select-value *:first-child {
        //         padding: 0px 5px;
        //     }

        //     .form__row--one {
        //         // background: #fff;
        //         // padding-left: 5px;
        //     }
        // }
    }
    &__title{
        color: #fff;
        font-family: $font-family;
        font-weight: 100;
        @include fontSize(subHeaderTitle);

        &--extra-margin {
            @include rem(margin-bottom, 40px);
        }
        &--inline {
            display: inline;
            @include rem(margin-left, 30px);
        }

    }
    &__text {
        color: #fff;
        line-height: $line-height-small;
        @include rem(margin-bottom, 25px);
        @include fontSize(paragraphMedium);
    }
    &__note {
        color: getGreytone("greytone-6");
        @include rem(margin, -10px 0 30px);
        @include fontSize(paragraphSmall);
    }

    .input-text-wrapper, textarea {
        width: 100%;
    }
    textarea {
        @include placeholder {
            @include fontSize(paragraphSmall);
        }
    }
    .btn {
        &--outline {
            color: #fff;
        }
    }

    // label {
    //     color: #fff;
    // }

    &--stretched{
        width: 100%;
        height: 100%;
        top:0;
        left:0;
        margin: 0;
        z-index: $modal-content;
        @include rem(padding, 30px 25px);
    }
    .loader {
        display: inline-block;
    }
}

.mdc-dialog__content {
    // hide the number counter that appears when you set a max length
    .md-char-counter {
        display: none;
    }
    md-input-container.char-counter .md-char-counter {
        color: black;
        display: flex;
    }

    .full-width-modal {
      width: 100%;
      height: 100%;
    }
    .block-container {
      display: block;
    }
    .grey-modal {
      background: #E5E5E5;
    }


    .transparent-modal {
        background: transparent;
        color: #fff;
        box-shadow: none;
        &.substitute-role {
          @media(min-width:1600px) {
            max-width: 60%;
          }
        }
        &.modal-no-scrolling {
          max-height: 100%;
          overflow-y: hidden;
          md-dialog-content {
            overflow: hidden;
          }
        }
    }

    .modal-scrolling {
      max-height: 100%;
      overflow-y: auto;
     }

    .half-modal {
      width: 480px;
      max-width: 480px;
      min-width: 480px;
    }

    .mid-width-modal {
        width: 700px;
    }


    .inner-scroll-modal {
        height: 100%;
        max-height: 80%;
        md-dialog-content {
            height: 100%;
            .scroll-container {
                height: 60%;
                .scrollable {
                    height: 60%;
                    overflow-y: scroll;
                }
            }
        }
    }

    .non-scrollable{
        overflow: hidden;
    }

    md-input-container {
        width: 100%;
    }

    h1, h4 {
        font-weight: normal;
    }

    .main-heading {
        font-size: 28px;
    }

    .commentary {
        font-size: 16px;
    }

    .add-new-deliverable-modal, .update-discount-modal {
        .main-heading {
            margin-top: 0px;
        }

        md-dialog-actions{
            padding: 0;
        }

        .clear-input {
            position: absolute;
            right: 10px;
            color: getGreytone('greytone-4');
            cursor: pointer;
        }
        .search-input {
            position: absolute;
            right: 10px;
            color: getGreytone('greytone-4');
        }

        ul.deliverable-type-result-list {
            color:black;
            list-style: none;
            padding: 0;
            max-height: 250px;
            overflow-y: scroll;
            margin: 0;

            li {
                border-bottom: 1px solid getGreytone('greytone-8');
                padding-left: 10px;
            }
        }

        .md-errors-spacer {
            display: none;
        }

        md-input-container {
            width: 100%;
            padding-bottom: 0px;
            // height: 32px;
            // margin-top: 25px;
            // margin-bottom: 0px;
        }

            md-input-container:not(.md-input-focused), md-input-container:not(.md-input-has-value) {
                label {
                    // line-height: 3.5;
                    // font-weight: bold;
                    // font-size: 14px;
                    // color: #666;
                }
            }

            md-input-container.md-input-focused, md-input-container.md-input-has-value {
                label {
                    line-height: normal;
                    font-weight: bold;
                    font-size: 16px;
                }
            }

            // [ng-messages] {
            //     color: #fba0b2 !important;
            //     position: absolute;
            //     bottom: -20px;
            // }

        .autocomplete-box {
            background-color: #fff;
            padding: 5px 20px;
            padding-bottom: 0px;

            label {
                // color: #666;
            }

            md-autocomplete, md-autocomplete-wrap {
                box-shadow: none;
                background-color: #fff;

                input {
                    padding: 0px;
                }
            }
        }

        .deliverable-input, .discount-input {
            color: black;
            background: #fff;
            padding: 5px 20px;
            margin-bottom: 15px;
            position: relative;
            md-input-container {
                textarea{
                    overflow-y: auto;
                }
            }

            &.show-char-count {
                md-input-container {
                    margin-bottom: 0;

                    .md-errors-spacer {
                        display: block;
                    }

                    .md-char-counter {
                        display: block;
                        color: grey;
                    }
                }
            }
        }

        .errors {
            font-size: 12px;
            font-weight: 200;
            padding-left: 20px;
            padding-top: 2.5px;
            color: #E1002C !important;
        }
    }

    .input-wrapper {
        background: white;
        padding: 5px 20px;
        margin-bottom: 25px;
        position: relative;

        md-input-container {
            margin-bottom: 0;
            textarea{
                overflow-y: auto;
            }
        }
    }

    .create-custom-component-modal {
        width: 700px;


        .md-char-counter {
            display: block;
            color: grey;
        }

        .rate-card--subheading{
            text-transform: uppercase;
            font-weight: 100;
            @include rem(margin-top, 15px);
            @include rem(margin-bottom, 0);
            @include rem(font-size, 9px);
        }

        md-switch {
            margin: 0;
        }

        pager-input {
            color: white;
            input {
                color: white;
            }
        }

        .role-item {
            @include rem(padding, 10px 0);
        }

        .collapsible-list {
            li {
                .arrow--down {
                    border-top-color: #ffffff;
                }
                .arrow--left {
                    border-right-color: #ffffff;
                }
            }
        }

        .md-button{
            text-transform: none;
        }

        md-select {
            color: black;
            @include rem(margin-bottom, 15px);
        }

        .role-header {
            padding-top: 0;
            .rate-card--subheading {
                margin: 0;
            }
        }
    }
}

.mdc-dialog__content, md-dialog-actions {
    .action-buttons {
        margin-top: 25px;
        text-align: center;
        button {
            display: inline-block;
            margin: 0;
            width: 100%;
            max-width: 480px;
            font-size: 14px;
            padding-top: 5px;
            padding-bottom: 5px;
            text-transform: capitalize;
        }

        &.close {
            position: absolute;
            top: 0;
            right: 30px;
            margin-top: 0;
        }

        button.btn--action {
            &:hover {
                background: getHeroColor("hero-orange-2");
            }

            &[disabled] {
                background-color: #96919d;
                color: #fff !important;
            }
        }

        button.btn--transparent {
            border: 2px solid #96919d;
            margin-top: 10px;
        }
    }
}

.item_content {
  padding: 1.5rem;
  display: block;
  font-size: 14px;
  border-top: 1px solid #CFCFCF;
  min-height: 56px;
  cursor: move;
  position: relative;
}

.item_content .material-icons {
  min-height: 24px;
  display: inline-flex;
  align-items: center;
}

.item_content:last-child {
  border-bottom: 1px solid #D0D0D0;
}

.item_content.active {
  background: #EFEFEF;
  border-top: 2px solid #E7E7E7;
}

.all-content {
  overflow-y: auto;
  max-height: calc(100vh - 350px);

  &.right-ul {
    max-height: calc(100vh - 385px) !important;
  }

  //states
  &.no-padding {
    padding: 0px;
  }

  &.no-margin {
    margin: 0px;
  }

  div {
    @include rem(padding, 15px);
    display: block;
    font-size: 14px;
    border-top: 1px solid #CFCFCF;
    min-height: 56px;
    position: relative;

    &:last-child {
      border-bottom: 1px solid #D0D0D0;
    }

    .material-icons {
      color: #D2D2D2;
      min-height: 24px;
      display: inline-flex;
      align-items: center;
    }

    // states;
    &.active {
      background: #EFEFEF;
      border-top: 2px solid #E7E7E7;
    }
  }
}

.mdc-dialog__title {
  margin: 0 !important;
  padding: 0 !important;
}

.mdc-dialog__title::before {
  display: none !important;
}

.mdc-dialog {
  .modal-container {
    display: flex;
    flex-direction: column;
    max-height: 90vh;
    min-width: 575px;
    max-width: 575px;

    &.change-output-modal {
      max-width: 870px;
      font-family: $font-families-newjuneregular;

      .submit-modal {
        display: inline-flex;
        flex-wrap: wrap;
        justify-content: center;

        .error-message {
          background: #f9d7d8;
          border: 1px solid #a7898b;
          border-radius: 2px;
          font-size: 14px;
          padding: 10px;
          font-family: $font-families-newjuneregular;
        }

        i {
          font-size: 14px;
        }

        .icon {
          display: inline-block;
          margin-right: 5px;
          color: #ff8c00;
          font-size: 24px;
        }

        .template-wrapper {
          display: inline-flex;
          width: 100%;
          flex-wrap: wrap;
        }

        .output-image-container {
          padding-top: 31px;
          max-width: 250px;
          display: flex;
          flex-direction: column;

          .title {
            flex: 1;

            span {
              color: $secondary-purple-magenta-p-900;
              font-weight: bold;
              text-align: center;
              overflow: hidden;
              white-space: normal;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
            }
          }

          .note {
            font-size: 12px;
            margin: 12px;
            line-height: normal;
          }
        }

        .template-preview {
          width: 210px;
          height: 285px;
          border: 1px solid #c7c6c6;
          margin: 20px;
          color: #dadada;
          font-size: 33px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .mdc-dialog__content {
      padding: 0;
      overflow-y: auto;
      -ms-overflow-style: none;

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
        background-color: $primary-blue-violet-500;
        border: 1px solid transparent;
        background-clip: content-box;
      }
    }

    .scope-ui-toggle .mdc-form-field {
      flex-direction: row;
      width: unset;
      gap: 8px;
    }
  }
}

.w-370 {
  min-width: 370px !important;
  max-width: 370px !important;
}
.w-450 {
  max-width: 450px;
}
.w-575 {
  max-width: 575px;
}
.w-700 {
  max-width: 700px !important;
  min-width: 700px !important;
}
.w-992 {
  max-width: 992px !important;
  min-width: 992px !important;
}
.w-full {
  max-width: 100% !important;
}


.modal {
  .gray {
    color: #BCBCBC !important;
  }
  .title-for-row {
    display: inline-flex;
    width: 100%;
    padding-right: 10px;
    height: 40px;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    &.border {
      border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    }

    .right {
      text-align: right;
    }
  }
  &__header {
    background: linear-gradient(to right, $secondary-purple-magenta-p-900, $secondary-purple-magenta-700);
    opacity: 95%;
    padding: 56px;
    position: relative;
    &--huge-text {
      flex-direction: column;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      padding: 30px !important;
      padding-left: 50px !important;
      .arrow-back {
        display: block !important;
        top: 20px;
      }
    }
    &--arrow {
      display: inline-flex;
      align-items: center;
      width: 100%;
      justify-content: center;
      .arrow-back {
        font-size: 24px;
        position: absolute;
        left: 0px;
        display: flex;
        height: 100%;
        align-items: center;
        margin-left: 20px;
        i {
          color: $primary-orange-p-500;
          cursor: pointer;
        }
      }
    }
    &--low-pdd {
      padding: 30px;
    }
  }
  &__body {
    padding: 56px;
    justify-content: right;
    .modal-costume-scope-container {
      padding-top: 0px;
      padding-bottom: 35px;
    }
    .modal-costume-table-header {
      height: 35px !important;
    }
    .modal-costume-table {
      max-height: 140px;
      overflow-y: auto;
      @media(min-width: 1600px) {
        max-height: 340px;
      }
      .main-scope-row-wrap {
        height: 40px !important;
      }
      .text {
        font-size: 14px !important;
        i {
          font-size: 18px;
          color: $primary-orange-p-500;
        }
      }
      md-input-container {
        margin: 0px;
        .md-select-value {
          font-size: 14px;
          border-bottom: none;
        }
      }
    }
    .library-error {
      font-size: 12px;
      font-weight: 600;
      color: #D93246;
      font-family: Roboto, "Helvetica Neue", sans-serif;
      text-transform: uppercase;
      padding-bottom: 15px;
    }
    &.padding-20 {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    &.padding-30 {
      padding-top: 30px;
      padding-bottom: 30px;
    }
    .auto-complete-form-field {
      width: unset !important;
    }
    .member-row {
      height: 97px;
      border-bottom: 1px solid $primary-blue-violet-200;
    }
    .member-img {
      width: 64px;
      height: 64px;
      padding: 0;
      border: none;
      overflow: hidden;
      border-radius: 200px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: $primary-blue-violet-800;
      color: $primary-orange-p-500;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: $font-families-newjunebold;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
    }
    .text-m {
      font: $product-ui-text-m;
    }
  }
  &__title {
    h1, h4 {
      color: $secondary-neutral-black-white;
      margin: 0px;
    }
  }
  &__text {
    color: rgba(255,255,255, .7);
    &.top-20 {
      margin-top: 20px;
    }
  }
  &__modal-link {
    padding-top: 20px;
    a {
      color: #ADAEB4;
      font-weight: 600;
    }
  }
}

.modal__header {
  .close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
    color: $secondary-neutral-black-white;
    outline: none;
  }

  span {
    color: $secondary-neutral-black-100;
    font-family: NewJuneRegular;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
}

.mat-mdc-dialog-actions.mdc-dialog__actions {
  justify-content: flex-end;
  padding: 24px 56px 56px 56px;

  &.no-body {
    padding-top: 56px;
  }
}
