/*  ========================================================================== */
/*  @group State (& Helpers) */
/*  ========================================================================== */

// * Hidden/ Visible classes

.is--visible {
    display: block;
}

.is--hidden {
    display: none;
}
 