
.animate-on-enter {
    &.ng-enter {
        -webkit-transition: 0.5s linear all;
        -moz-transition: 0.5s linear all;
        -ms-transition: 0.5s linear all;
        -o-transition: 0.5s linear all;
        transition: 0.5s linear all;
        overflow:hidden;
    }

    &.ng-enter {
        max-height: 0;
        opacity: 0;

        &.ng-enter-active {
            max-height: 999px;
            opacity:1;
        }

    }
}

