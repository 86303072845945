/*  ========================================================================== */
/*  @group Layout */
/*  ========================================================================== */

/*  ========================================================================== */
/*  @group Layout Helpers */
/*  ========================================================================== */

@mixin l-constrained {
  // display: block;
  width: 95%;
  @include rem(max-width, 1680px);
  margin: 0 auto;
}

body {
  background: white;
  margin: 0;
  &.purple {
    @include background-gradient();
  }
}

site-header {
  position: relative;
  z-index: 1000;
}

.site-wrapper.hide-header {
  site-header {
    display: none;
  }

  iframe.app-frame {
    height: 100vh;
  }
}

.site-wrapper:not(.hide-header) {
  main {
    top: 70px;
  }

  iframe.app-frame {
    height: calc(100vh - 70px);
  }
}

iframe.app-frame {
  width: 100%;
}

app-root.transparent {
  background: transparent;
}

.l-constrained {
  @include l-constrained;
  @include clearfix;
  .right {
    margin-left: 30px;
    margin-right: 0px;
  }
}

.container {
  min-height: 100%;
  @include rem(padding, 20px);
  @include clearfix;

  &--grey {
    background: getGreytone('greytone-9');
  }
  &--white {
    background-color: #fff;
  }
}

.padded {
  &--right {
    @include media($tablet) {
      padding-right: 20%;
    }
  }
}

.float {
  &--left {
    float: left;
  }
  &--right {
    float: right;
  }
}

// This should be called just row after full implementation
.scope-row,
.row {
  clear: both;
  @include clearfix;

  &--bordered {
    border-bottom: 1px solid getGreytone('greytone-7');
  }
}

// Temporary - To be removed, consolidated

.site-section {
  @include rem(margin, 40px 0);
  @include clearfix;
}

.inline-block {
  display: inline-block !important;
}

.clear {
  clear: both;
}
.dissable-scrolling {
  md-autocomplete-wrap {
    position: initial !important;
  }
}
.orange {
  color: $primary-orange-p-500 !important;
}
.pointer {
  cursor: pointer;
}
.placeholder {
  color: getGreytone('greytone-3');
}

.text-box {
  @include rem(margin, 20px 0);
  p {
    @include fontSize(paragraphSmall);
  }
}

// display options
.d {
  &-block {
    display: block;
  }

  &-inline-block {
    display: inline-block !important;
  }

  &-flex {
    display: flex;

    &-inline {
      display: inline-flex;
    }

    &-justify {
      &-center {
        justify-content: center;
      }
      &-end {
        justify-content: flex-end;
      }
      &-start {
        justify-content: flex-start;
      }
      &-between {
        justify-content: space-between;
      }
    }

    &-align {
      &-center {
        align-items: center;
      }
      &-end {
        align-items: flex-end;
      }
      &-start {
        align-items: flex-start;
      }
    }

    &-direction {
      &-colum {
        flex-direction: column;
      }
    }
  }
}
