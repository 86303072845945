@import 'styles/base/variables';

.main-nav-container {
  display: flex;
  align-items: center;
  height: 100%;

  .inner-nav-container {
    margin-left: 5%;
  }
}

.site-header {
  background: linear-gradient(90deg, #2f1139 0%, #48285b 100%);

  .agency {
    background: linear-gradient(90deg, #2f1139 0%, #48285b 100%);
  }

  .legal {
    background: linear-gradient(90deg, #84ccce, #84cccee0);
  }

  .client {
    background: linear-gradient(90deg, #331d5d, #7e36b5);
  }

  z-index: $header-content;
  opacity: 0.99;
  width: 100%;
  min-width: 1091px;
  font-size: 62.5%;
  padding: 15px 40px 15px 40px;
  height: 100%;

  &__logo {
    &__image {
      display: block;
      max-width: 220px;

      > img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  &__nav {
  }
  &__support {
    float: right;

    @include rem(margin, 10px 0 0);

    &__nav {
      display: flex;
      align-items: center;
      gap: 24px;

      li {
        &:first-child {
          padding: 0px;
          display: flex;
          height: 40px;
          align-items: center;
          font-size: 14px;
          font-family: $font-families-newjunebold;

          a {
            width: auto;
            height: auto;
            &:hover {
              border-bottom: none;
              padding-bottom: 0px;
            }
          }
        }

        .btn--img {
          width: 32px;
          height: 32px;
          margin-top: 5px;
          border-radius: 50px;
          img {
            @include responsiveImage();
          }
        }
      }
    }

    &__sub-menu {
      position: absolute;
      top: 100px;
      margin-left: -135px;
      border-radius: 2px;
      min-width: 164px;
      z-index: $above-content;

      li {
        a {
          padding: 5px 10px;
          width: 100%;

          @include fontSize(navSmall);

          a:hover {
            background: getGreytone('greytone-3');
          }
        }
      }
    }
  }

  a.notifications {
    display: flex;
    align-items: center;
    padding-right: 11px;
    position: relative;
    user-select: none;

    i {
      text-align: center;
      width: 100%;
      font-size: 30px;

      &.has-notification {
        animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
        perspective: 1000px;

        @keyframes shake {
          10%,
          90% {
            transform: translate3d(-1px, 0, 0);
          }

          20%,
          80% {
            transform: translate3d(2px, 0, 0);
          }

          30%,
          50%,
          70% {
            transform: translate3d(-4px, 0, 0);
          }

          40%,
          60% {
            transform: translate3d(4px, 0, 0);
          }
        }
      }
    }

    .unread-counter {
      display: flex;
      width: 16px;
      height: 16px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 8px;
      border-radius: 200px;
      background: $semantic-error-p-600;
      position: absolute;
      right: -8px;
      top: -5px;
      font-size: 8px;
      font-family: $font-families-newjunebold;
    }
  }
}

.CLIENT-theme .site-header {
  @include background-gradient-light-purple();

  .company-logo {
    display: flex;
    margin-right: 15px;
    align-items: center;
    img {
      max-width: 100px;
      max-height: 60px;
      border: solid 1px white;
      border-radius: 1px;
      padding: 2px;
    }
  }
}
.LEGAL-theme .site-header {
  @include background-gradient-light-teal();

  li > a {
    color: black;
  }
}

.user-notifications {
  height: calc(100% - 80px); //Header is removed
  width: 400px;
  background-color: white;
  position: fixed;
  z-index: 10;
  right: -450px;
  box-shadow: 3px 0 15px grey;
  transition: 0.4s;
  overflow-y: scroll;

  .heading {
    display: flex;
    justify-content: space-between;
    .title {
      color: grey;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 600;
      font-family: Roboto, 'Helvetica Neue', sans-serif;
      line-height: 1em;
      margin-left: 30px;
      margin-top: 20px;
      display: block;
    }
    .opt-wrap {
      position: relative;
      i.opt-btn {
        outline: 0;
        position: absolute;
        right: 10px;
        top: 12px;
        cursor: pointer;
        color: grey;
      }
      .options {
        top: 40px;
        position: absolute;
        right: 10px;
        width: 150px;

        &:after,
        &:before {
          bottom: 100%;
          right: 7px;
          border: solid transparent;
          content: ' ';
          height: 0px;
          width: 0;
          position: absolute;
          pointer-events: none;
        }

        &:after {
          border-color: rgba(136, 183, 213, 0);
          border-bottom-color: #ffffff;
          border-width: 6px;
        }
        &:before {
          border-color: rgba(194, 225, 245, 0);
          border-bottom-color: #dedede;
          border-width: 7px;
          margin-right: -1px;
        }

        li {
          background-color: #fbfbfb;
          color: $primary-orange-p-500;
          padding: 10px;
          box-shadow: 0 0 3px 0 #d4d4d4;
          cursor: pointer;

          .opt-icon {
            font-size: 14px;
            vertical-align: middle;
            margin-right: 5px;
          }
        }
      }
    }
  }

  &.active {
    right: 0;
    transition: 0.4s;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    padding-bottom: 10px;
    line-height: 1.5;

    &.notifications {
      display: flex;
      flex-flow: column;
      margin-top: 25px;

      .mark-read {
        display: none;
      }

      li {
        padding: 10px 30px;
        border-top: 1px solid #f9f9f9;
        &.notification {
          &:hover {
            box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.09);
            cursor: pointer;

            .mark-read {
              display: block;
            }
          }

          &.unread {
            background-color: #fbfbfb;
          }

          .content {
            display: flex;
            align-items: center;
          }

          .datetime {
            display: block;
            margin-top: 5px;
          }
          .icon {
            border-radius: 20px;
            border: 1px solid lightgray;
            height: 35px;
            width: 35px;
            display: flex;
            align-items: center;

            i {
              width: 100%;
              text-align: center;
              font-size: 17px;
              color: #ff6b00;
            }
          }
        }
      }
    }

    a {
      color: $primary-orange-p-500;
    }
  }
}

.site-header__support__nav {
  float: right;

  li.menu-profile-button {
    width: 32px;
    min-width: 32px;
    height: 32px;
    padding: 0;
    border: none;
    overflow: hidden;
    border-radius: 200px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    a {
      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: inherit;
      }
    }
  }

  li.support-link a {
    color: $secondary-neutral-black-white;
    font-variant-numeric: lining-nums proportional-nums;

    font-family: $font-families-newjunebold;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }

  .child-switcher {
    border: 2px solid white;
    border-radius: 20px;
    padding: 5px 10px;
    color: white;

    font-size: 12px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    cursor: pointer;
    width: 200px;
    position: relative;
    transition: ease 0.3s width;
    user-select: none;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
      width: 150px;
    }
    &.active {
      border-color: $primary-orange-p-500;
    }

    &.expanded {
      width: 230px;
      @media screen and (max-width: 1300px) {
        width: 150px;
      }
    }

    i {
      margin-left: 10px;
    }
  }
}

ul#childCompanySwitcherMenu {
  position: absolute;
  top: 16px;
  background-color: white;
  right: 10px;
  border: 2px solid #332d42;
  width: 125px;
  border-radius: 0 0 23px 23px;
  color: black;
  padding: 0;
  opacity: 0;
  transition: ease 0.3s all;
  overflow: hidden;
  z-index: 1;

  &.expanded {
    opacity: 1;
    width: 210px;

    @media screen and (max-width: 1300px) {
      width: 125px;
    }
  }
  li {
    padding: 0px;
    display: flex;
    height: 40px;
    align-items: center;
    font-size: 12px;
    margin-right: 10px;
    border-bottom: #efebeb 1px solid;
    margin: 0;
    cursor: pointer;
    justify-content: left;
    padding-left: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    img {
      width: 24px;
      margin-right: 10px;
    }
    &:hover {
      background-color: getGreytone('greytone-8');
    }
    &:last-child {
      border: none;
    }
  }
}
