/*  ========================================================================== */
/*  @group Global*/
/*  ========================================================================== */
md-input-container:not([md-no-float]) .md-select-placeholder span:after {
  opacity: 0 !important;
}
md-input-container.md-default-theme:not(.md-input-focused):not(.md-input-invalid) label.md-required:after,
md-input-container:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {
  opacity: 0 !important;
}
* {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.no-arrows[type=number] {
  -moz-appearance: textfield;
}

html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-smoothing: always;
  color: black;
}

html {
  // Remove 300ms delay on Windows Mobile.
  // Ref: http://updates.html5rocks.com/2013/12/300ms-tap-delay-gone-away
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  &.no-scroll {
    overflow: hidden;
  }
}

::-webkit-input-placeholder {
}

:-moz-placeholder {
}

[type='search'] {
  /* resets webkit styling on search inputs */
  -webkit-appearance: none;
}
[type='submit'],
button {
  border: none;
}

/* Style the webkit validation message for forms */
::-webkit-validation-bubble-message {
  padding: 40px;
}

/*  ========================================================================== */
/*  @group Font Stacks */
/*  ========================================================================== */

body {
  font-family: $font-family;

  :root {
    --ck-color-text: $secondary-neutral-black-white;
  }

  ckeditor {
    .ck-editor__editable {
      max-height: 100px;
    }

    .ck.ck-editor__main > .ck-editor__editable:not(.ck-focused) {
      border-left-width: 0;
      border-right-width: 0;
      border-top-width: 0;
      border-radius: 0;
      border-bottom-width: 1px;
      border-color: $primary-blue-violet-800;
      background-color: transparent;
    }

    .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
      border-left-width: 0;
      border-right-width: 0;
      border-top-width: 0;
      border-bottom-width: 2px;
      border-color: $primary-blue-violet-800;
      box-shadow: none;
      background-color: transparent;
    }

    .ck-toolbar {
      background-color: $primary-blue-violet-800 !important;
    }

    .ck-editor__editable_inline {
      padding-left: 20px;
    }

    .ck-button.ck-on {
      background: $primary-blue-violet-300 !important;
    }

    .ck-icon path {
      fill: $secondary-neutral-black-white !important;
    }

    .ck-dropdown__panel {
      fill: $primary-blue-violet-800 !important;
      background: $primary-blue-violet-800 !important;
    }

    ul,
    ol {
      padding-inline-start: 40px;
    }
  }

  .mat-icon {
    overflow: visible;
  }

  .mat-mdc-form-field-error-wrapper {
    padding: 0;
  }

  .mat-mdc-form-field-error {
    color: $semantic-error-p-600;
    font: $product-ui-text-s;
  }
}

h1,
h2 {
  font-family: $font-family;
  font-weight: bold;
  outline: none;
}
h1 {
  font: $product-heading-h1;

  &.product-heading-bold {
    font: $product-heading-bold-h1;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    display: inline-block;
  }
}
h2 {
  font: $product-heading-h2;

  &.product-heading-bold {
    font: $product-heading-bold-h2;
  }
}
h3,
h4,
h5,
h6 {
  font-family: $font-family;
  @include rem(margin, 20px 0);
  outline: none;
}
h3 {
  font: $product-heading-h3;

  &.product-heading-bold {
    font: $product-heading-bold-h3;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    display: inline-block;
  }
}
h4 {
  font: $product-heading-h4;

  &.product-heading-bold {
    font: $product-heading-bold-h4;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    display: inline-block;
  }
}
h5 {
  font: $product-heading-h5;

  &.product-heading-bold {
    font: $product-heading-bold-h5;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    display: inline-block;
    line-height: 1.5rem;
  }
}
h6 {
  font: $product-heading-h6;

  &.product-heading-bold {
    font: $product-heading-bold-h6;
    margin-top: 0;
    margin-bottom: 0;
    width: fit-content;
    display: inline-block;
    line-height: 16px;
  }
}

strong,
.strong {
  outline: none;
  font-family: $font-family;
  font-weight: bold;
}

.scopemark {
  color: getHeroColor('hero-orange-1');
  font-weight: bold;
}

button {
  &.text-link {
    color: $primary-blue-violet-p-900;
    font: $product-ui-text-label-l;
    align-items: center;
    display: flex;
    gap: 4px;
    height: 24px;

    &[disabled] {
      color: $primary-blue-violet-600;

      .mat-icon {
        color: $primary-blue-violet-600 !important;
      }
    }

    &.small {
      font: $product-ui-text-label-m;
    }

    .mat-icon.small {
      font-size: 18px;
    }

    .mat-icon.f-22 {
      font-size: 22px;
    }

    &:hover:not([disabled]) {
      color: $primary-orange-600;

      .mat-icon {
        color: $primary-orange-600 !important;
      }
    }

    &:active:not([disabled]) {
      color: $primary-orange-700;

      .mat-icon {
        color: $primary-orange-700 !important;
      }
    }

    .mat-icon.icon-small {
      height: 16px;
      width: 16px;
      font-size: 16px;
    }
  }
}

/*  ========================================================================== */
/*  Text modification classes */
/*  ========================================================================== */

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-uppercase {
  text-transform: uppercase;
}

/*  ========================================================================== */
/*  Float modification classes */
/*  ========================================================================== */
.right {
  float: right !important;
}
.left {
  float: left;
}

/*  ========================================================================== */
/*  @group Links */
/*  ========================================================================== */

::-moz-selection {
}

::selection {
}

a::-moz-selection {
}

a::selection {
}

// TODO remove this default a tag color. just too many breaking changes for scope of ticket

a {
  color: $color-link-default;
  text-decoration: none;
  word-wrap: break-word;

  &.dark {
    color: getGreytone('greytone-1');
  }
  &.black {
    color: black;
    &:hover {
      text-decoration: underline;
    }
  }
}

div,
img,
span {
  outline: none;
}

a,
a:visited {
  word-wrap: break-word;
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  outline: 0;
}

a:hover {
  cursor: pointer;
  color: $color-link-hover;
}

a:active {
  color: $color-link-active;
}

input,
textarea {
  color: #000;
}
.sortorder {
  color: #ff6b00;
  margin-left: 5px;
}
.sortorder:after {
  content: '\25b2';
}
.sortorder.reverse:after {
  content: '\25bc';
}

.under-header-info {
  margin-top: 0px;
  margin-bottom: 40px;
  font-size: 12px !important;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.14), 0 0px 0px rgba(0, 0, 0, 0.1);
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  padding: 8px 15px;
  .under-header-row {
    line-height: 22px;
    display: flex;
    flex-flow: wrap;
  }
  md-input-container {
    padding: 0px;
    margin-bottom: 4px;
  }
  .bold {
    color: #ff6b00;
    margin-right: 4px;
  }
  .m_right_15 {
    font-weight: 600;
    padding: 0 0 5px 0;
  }
  .md-select-value {
    min-height: 20px;
    font-size: 12px !important;
    padding: 0px;
    border-bottom: none !important;
    min-width: 36px;
    display: flex;
    align-items: center;
  }
  .md-select-icon {
    margin: 0px;
    /* align-items: flex-start; */
    display: flex;
    min-height: 12px;
  }
  .m-bottom-input {
    margin-bottom: 12px;
    margin-left: 5px;
  }
}
.btn-wrap-cookies {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  .btn {
    padding: 10px 40px;
    font-size: 16px;
    font-weight: 400;
    border-radius: 100px;
    background: #ff6b00;
    display: block;
    max-width: 150px;
  }
}

.margin-r10 {
  margin-right: 10px;
}
.th-party-input-inline {
  md-input-container {
    width: 100%;
    margin: 0px;
    padding: 0px;
    font-size: 16px;
    font-weight: 400;
    display: flex;
    max-width: 450px;
    align-items: center;
  }
  .md-resize-wrapper {
    width: 100%;
  }
  textarea {
    height: 40px;
    padding: 0px;
    width: 100%;
  }
  .md-errors-spacer {
    display: none;
  }
  .th-name {
    padding-top: 2px;
  }
}

.bold-uppercase-title {
  color: #ff6b00;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
  line-height: 1em;
}

article.invalid-page {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: grey;
  padding: 50px 0;
  width: 100vw;
}

.mat-mdc-dialog-container {
  .mdc-dialog__surface {
    border-radius: $border-radius-xl !important;
  }
}

.mdc-button.mdc-button--unelevated {
  height: 32px;
  padding: 0px 20px;
  align-items: center;
  border-radius: 200px;
  font: $product-ui-text-label-l;
}

.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-label-text-color: #{$primary-blue-violet-600};
  --mdc-filled-button-disabled-container-color: #{$primary-blue-violet-50};

  &.light-bg {
    --mdc-filled-button-disabled-container-color: #{$primary-blue-violet-100};
  }
}

.mdc-button.mdc-button--unelevated:not(:disabled) {
  background: $primary-orange-p-500;
  color: $secondary-neutral-black-white !important;
}

.mat-divider,
.mat-divider-vertical {
  border-color: $secondary-neutral-black-300;
}

.mat-content-hide-toggle {
  margin: 0 !important;
}

mat-checkbox .mdc-checkbox {
  --mdc-checkbox-state-layer-size: 28px;
  --mdc-checkbox-selected-icon-color: #{$primary-orange-800};
  --mdc-checkbox-selected-focus-icon-color: #{$primary-orange-800};
  --mdc-checkbox-selected-pressed-icon-color: #{$primary-orange-800};
  --mdc-checkbox-selected-hover-icon-color: #{$primary-orange-800};
  --mdc-checkbox-selected-hover-state-layer-color: #{$primary-orange-100};
  --mdc-checkbox-selected-hover-state-layer-opacity: 1;
  --mdc-checkbox-unselected-hover-state-layer-color: #{$primary-orange-100};
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0;
  --mdc-checkbox-selected-focus-state-layer-color: #{$primary-orange-100};
  --mdc-checkbox-selected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-focus-state-layer-color: #{$primary-orange-100};
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  --mdc-checkbox-selected-pressed-state-layer-color: #{$primary-orange-100};
  --mdc-checkbox-selected-pressed-state-layer-opacity: 0;
  --mdc-checkbox-unselected-pressed-state-layer-color: #{$primary-orange-100};
  --mdc-checkbox-unselected-pressed-state-layer-opacity: 0;

  height: 16px;
  width: 16px;
  flex: 0 0 16px;
  padding: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);

  .mdc-checkbox__background {
    height: 16px;
    width: 16px;
    top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
    left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
  }

  .mat-mdc-checkbox-touch-target {
    height: 28px;
    width: 28px;
  }
}

.mat-mdc-button:not(:disabled) {
  --mdc-typography-button-font-family: #{$font-families-newjunebold};
  --mdc-text-button-label-text-color: #{$primary-blue-violet-p-900};
}

body {
  --mdc-typography-body1-letter-spacing: normal;
  --mdc-typography-button-letter-spacing: normal;

  .mat-mdc-menu-content {
    padding-top: 0;
    padding-bottom: 0;

    .mat-divider {
      margin: 4px;
    }
  }
}

.mat-mdc-button.cdk-keyboard-focused,
.mat-mdc-button:focus {
  .mat-mdc-button-persistent-ripple::before {
    opacity: 0;
  }
}

.cdk-overlay-backdrop.customize + .cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop.options-menu + .cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop.scope-options-menu + .cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop.library-options-menu + .cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane::before,
  .cdk-overlay-pane::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    right: 5px;
    z-index: 999;
  }

  &[style*='justify-content: flex-start'] {
    .cdk-overlay-pane {
      margin-top: 12px;
      margin-right: -11px;
    }

    .cdk-overlay-pane::before {
      border-width: 0 8px 12px;
      border-color: transparent transparent #fff;
      top: 0;
    }
  }

  &[style*='justify-content: flex-end'] {
    .cdk-overlay-pane {
      margin-bottom: 11px;
      margin-right: -11px;
    }

    .cdk-overlay-pane::after {
      border-width: 12px 8px 0;
      border-color: #fff transparent transparent;
      bottom: 0;
    }
  }
}

.cdk-overlay-backdrop.options-menu + .cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane::before,
  .cdk-overlay-pane::after {
    right: 5px;
  }

  .cdk-overlay-pane {
    margin-right: 0 !important;
  }
}

.cdk-overlay-backdrop.scope-options-menu + .cdk-overlay-connected-position-bounding-box,
.cdk-overlay-backdrop.library-options-menu + .cdk-overlay-connected-position-bounding-box {
  .cdk-overlay-pane::before,
  .cdk-overlay-pane::after {
    right: 7px;
  }

  .cdk-overlay-pane {
    margin-right: -9px !important;
  }

  .mat-mdc-menu-item {
    min-width: 218px;
    padding: 16px;
    gap: 8px;

    .mdc-list-item__primary-text {
      font: $product-ui-text-l;
    }

    mat-icon {
      margin-right: 0;
    }
  }

  span.mdc-list-item__primary-text {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    word-wrap: anywhere;

    span {
      color: $primary-blue-violet-p-900;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: $font-families-newjuneregular;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
    }
  }
}

.mat-mdc-checkbox label {
  font: $product-ui-text-l;
}

.mdc-checkbox .mdc-checkbox__native-control:focus ~ .mdc-checkbox__ripple:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 3px;
  outline: 2px solid -webkit-focus-ring-color;
}

.mat-mdc-form-field {
  --mdc-typography-body1-font-family: $font-families-newjuneregular;
}

.mat-primary {
  --mdc-theme-primary: $primary-blue-violet-p-900;

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    color: $primary-orange-p-500;
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
    background: $primary-orange-p-500;
  }
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  background: unset;

  &:hover {
    background: $primary-orange-50;
  }
}

.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
  font-family: $font-families-newjunebold;
}

.mat-mdc-option {
  font-family: $font-families-newjuneregular;

  &:hover:not(.mdc-list-item--disabled) {
    background: $primary-orange-50;
  }

  &:focus.mdc-list-item {
    background: $primary-orange-100;
  }
}

.fill {
  font-variation-settings: 'FILL' 1;
}

[mat-mini-fab]:not(.add-comment) {
  width: 24px;
  height: 24px;
  justify-content: center;
  align-items: center;
  background: $primary-orange-50;
  border: 1.5px solid $primary-orange-p-500;
  box-shadow: none !important;

  .material-icons {
    color: $primary-blue-violet-p-900 !important;
    padding: 4px 0;
  }

  .mat-mdc-button-touch-target {
    width: 24px;
    height: 24px;
  }
}

.mdc-text-field--filled .mdc-line-ripple::after {
  border-bottom-color: $primary-orange-p-500;
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled),
.mdc-text-field--invalid:not(.mdc-text-field--disabled):hover {
  .mdc-line-ripple::before {
    border-bottom-color: $primary-blue-violet-600;
  }
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
  top: 38px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent;
}

.ng-trigger-transformMenu.no-animate {
  animation: none !important;
  transition: none !important;
  transform-origin: center center !important;
  animation-duration: 0s !important;
  transform: none !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.range-slider {
  .ngx-slider .ngx-slider-pointer {
    background-color: transparent;
  }

  .ngx-slider .ngx-slider-pointer:after {
    width: 24px;
    height: 24px;
    top: 4px;
    left: 4px;
    border-radius: 12px;
  }

  .ngx-slider .ngx-slider-pointer:hover:after {
    background-color: $primary-orange-600;
  }

  .ngx-slider .ngx-slider-pointer:not(:hover):after {
    background-color: $primary-orange-p-500;
  }

  .ngx-slider .ngx-slider-bar {
    height: 2px;
    background: $primary-blue-violet-300;
  }

  .ngx-slider .ngx-slider-tick {
    background-color: $primary-blue-violet-300;
    width: 8px;
    height: 8px;
  }

  .ngx-slider-tick-legend {
    font: $product-ui-text-m;
    color: $primary-blue-violet-700;

    b {
      font: $product-ui-text-bold-l;
      color: #484e5d;
    }
  }
}

.toggle-button {
  border: none;
  background: $secondary-neutral-black-white;
  padding: 0;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;

  & .material-icons {
    color: $primary-orange-p-500;
    font-size: 16px;
    line-height: 16px;
  }

  /* Accessibility: Adding focus styles */
  &:focus {
    box-shadow: 0 0 3px $primary-orange-p-500;
  }
}

.hidden {
  display: none !important;
}

// Skeleton Start

.skeleton-loader {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.skeleton-item {
  width: 100%;
  height: 30px;
  margin-bottom: 10px;
  background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
  background-size: 200% 100%;
  animation: loading 1.5s infinite;
  border-radius: 4px;

  &.thinner {
    height: 15px;
    width: 70%;
  }

  &.small {
    width: 10%;
  }
}

@keyframes loading {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

// Skeleton End

.icon-spinner {
  animation: spin-animation 1s infinite;
  -webkit-animation: spin-animation 1s infinite;
  display: inline-block;
}

@keyframes spin-animation {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@-webkit-keyframes spin-animation {
  0% {
    transform: rotate(359deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
