.card-wrapper {
  display: grid;
  gap: 1rem;
  min-width: 1254px;
  grid-template-columns: repeat(auto-fit, minmax(222px, calc(20% - 13px)));
}

.card-skeleton {
  background-color: $primary-blue-violet-100;
  border-radius: 12px;
  overflow: hidden;
  padding: 24px;
  height: 126px;
  background-image: linear-gradient(90deg, rgba(#fff, 0), rgba(#fff, 0.5), rgba(#fff, 0));
  background-size: 160px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}
