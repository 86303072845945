@import 'styles/base/variables';

.datapoint-nav-container {
  display: flex;
  width: 254px;
  padding: 0 8px 0 40px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  background: $primary-blue-violet-50;
  box-shadow: 6px 0 6px 4px rgba(72, 78, 93, 0.06);
  z-index: 1;
  margin-bottom: 22px;
  overflow: auto;

  .title {
    color: $secondary-neutral-black-900;
  }

  ul {
    width: 100%;
  }

  .title {
    display: flex;
    height: 80px;
    padding: 8px 16px 8px 0;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    align-self: stretch;
  }

  .data-tab-nodes {
    display: flex;
    width: 100%;
    height: 48px;
    min-height: 40px;
    max-height: 48px;
    padding: 16px 8px 16px 16px;
    align-items: center;
    gap: 2px;
    flex-shrink: 0;
    color: $secondary-neutral-black-900;
    border-radius: 4px;
    font: $product-ui-text-l;

    &:not(.disabled) {
      cursor: pointer;
    }

    &.active {
      padding: 16px 0 16px 24px;
      align-self: stretch;
      background: $primary-blue-violet-200;
      font-family: $font-families-newjunebold;

      mat-icon {
        color: $primary-orange-p-500;
      }
    }

    &:hover:not(.active):not(.disabled) {
      background: $primary-orange-50;
      font-family: $font-families-newjunebold;
    }

    &.child {
      height: 32px;
      min-height: 32px;
      padding: 16px 8px 16px 40px;

      &.active {
        padding: 16px 0 16px 48px;
      }
    }
  }
}

.datapoint-content-container {
  height: 100%;
  flex-grow: 1;
  overflow: auto;
}
