
.icon {

    display: inline-block;

    @include rem(width, 24px);
    @include rem(height, 24px);

    &--border-dark {
        border: 1px solid getTextColor('text-primary') !important;
        border-radius: 50% !important;
    }

    &--border-white {
        border: 1px solid getTextColor('text-white') !important;
        border-radius: 50% !important;
    }

    &--large {
        @include rem(width, 40px);
        @include rem(height, 40px);
        border-width: 2px !important;
    }

    &.delete {
        &--dark {
            @include background-png("v2/delete_dark.png");
        }
        &--white {
            @include background-png("v2/delete_white.png");
        }
    }

    &.edit {
        &--dark {
            @include background-png("v2/edit_dark.png");
        }
        &--white {
            @include background-png("v2/edit_white.png");
        }
    }

    &.archive {
        &--dark {
            @include background-png("v2/archive_dark.png");
        }
        &--white {
            @include background-png("v2/archive_white.png");
        }
    }
    &.plus {
        &--dark {
            @include background-png("v2/plus_small_dark.png");
        }
        &--white {
            @include background-png("v2/plus_small_white.png");
        }
    }
    &.plus-large {
        &--dark {
            @include background-png("v2/plus_dark.png");
        }
        &--white {
            @include background-png("v2/plus_white.png");
        }
    }
    &.tick {
        @include background-png("v2/tick_green.png");
        &--dark {
            @include background-png("v2/tick_dark.png");
        }
        &--white {
            @include background-png("v2/tick_white.png");
        }
    }
    &.cross {
        @include background-png("v2/cancel_red.png");
        &--dark {
            @include background-png("v2/cancel_dark.png");
        }
        &--white {
            @include background-png("v2/cancel_white.png");
        }
    }
    &.danger {
        @include background-png("v2/warning_red.png");
    }
    &.copy {
        &--dark {
            @include background-png("v2/clone_dark.png");
            background-size: 18px;
            background-position-y: 2px
        }
        &--white {
            @include background-png("v2/clone_white.png");
            background-size: 18px;
            background-position-y: 2px;
        }
    }
    &.heart {
        &--dark {
            @include background-png("v2/heart_dark.png");
        }
        &--white {
            @include background-png("v2/heart_white.png");
        }
    }
    &.star {
        &--dark {
            @include background-png("v2/star_dark.png");
        }
        &--white {
            @include background-png("v2/star_white.png");
        }
    }
    &.clock {
        &--dark {
            @include background-png("v2/clock_dark.png");
        }
        &--white {
            @include background-png("v2/clock_white.png");
        }
    }
    &.search {
        &--dark {
            @include background-png("v2/search-small_dark.png");
        }
        &--white {
            @include background-png("v2/search-small_white.png");
        }
    }
    &.menu {
        &--dark {
            @include background-png("v2/tags_dark.png");
            background-size: 16px;
        }
        &--white {
            @include background-png("v2/tags_white.png");
            background-size: 16px;
        }
    }
    &.grip {
        height: 24px;
        width: 12px;
        @include background-png("v2/grip_grey.png");
        background-size: auto 100%;
    }
    &.grip-green {
        height: 24px;
        width: 12px;
        @include background-png("v2/grip_green.png");
        background-size: auto 100%;
    }
    &.comment {
        &--dark {
            @include background-png("v2/comments_dark.png");
            background-size: 16px;
        }
        &--white {
            @include background-png("v2/comments_white.png");
            background-size: 16px;
        }
    }

    &.arrow{

        height: 0;
        width: 0;

        &--down {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 8px solid #fff;
            margin-top: 1px;
        }
        &--up {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 8px solid #fff;
            margin-bottom: 1px;
        }
        &--left {
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-right: 8px solid #fff;
            margin-right: 5px;
        }
    }
    &:hover:not(.no-hover) {
        opacity: 0.4;
    }

    &.custom-item {
        border: 1px solid #4ed4c2;
        text-align: center;
        border-radius: 3px;
        width: 50px;
        height: 21px;
        background-color: #c3f0ea;
        color: #69dbcb;
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 20px;

        &:before {
            content: "Custom";
        }

        &:hover:not(.no-hover) {
            opacity: 1;
        }
    }

    &.scopemark-tag {
        border: 1px solid #4ed4c2;
        text-align: center;
        border-radius: 3px;
        min-width: 58px;
        height: 21px;
        color: #ff6b00;
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 900;
        font-style: normal;
        line-height: 20px;

        &:before {
            content: "ScopeMark";
        }

        &:hover:not(.no-hover) {
            opacity: 1;
        }
    }

    &.library-tag {
        border: 1px solid #4ed4c2;
        text-align: center;
        border-radius: 3px;
        min-width: 58px;
        height: 21px;
        color: #000;
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 900;
        font-style: normal;
        line-height: 20px;

        &:before {
            content: "Library";
        }

        &:hover:not(.no-hover) {
            opacity: 1;
        }
    }


    &.traffic-import-item {
        border: 1px solid #4ed4c2;
        text-align: center;
        border-radius: 3px;
        width: 85px;
        height: 21px;
        background-color: #c3f0ea;
        color: #69dbcb;
        font-size: 8px;
        text-transform: uppercase;
        font-weight: 900;
        line-height: 23px;

        &:before {
            content: "Traffic Imported";
        }

        &:hover:not(.no-hover) {
            opacity: 1;
        }
    }

    &.left-spacer{
        margin-left : 10px;
    }
    &.no-margin {
      margin: 0px;
    }

}
.arrow-helper {
    display: inline-block;
    width: 15px;

    .arrow {
        @include rem(margin, 5px 0px);
    }
}

i.hover-orange:hover {
    color: $primary-orange-p-500!important;
}
