.page {
  &-container {
    display: grid;
    grid-template-rows: auto 1fr;
    gap: 8px;
    padding-top: 16px;
  }

  &-sub-container {
    width: 100%;
    max-width: 1440px;
    padding: 0 40px;
    margin-left: auto;
    margin-right: auto;

    mat-divider[vertical] {
      height: 32px;
    }
  }

  &-tabs {
    display: block;

    .nav-tabs {
      border-bottom: 1px solid $secondary-neutral-black-300;
      gap: 16px;
      display: flex;
    }
  }
}
