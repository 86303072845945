@import 'styles/base/variables';

//text
@media (max-width: 1200px) {
  body {
    // min-width: 1200px;
    overflow-y: scroll;
  }
}

.show {
  display: block;
}

.hide {
  display: none;
}

[hidden] {
  display: none !important;
}

.resize-none {
  resize: none !important;
}

.scope-message {
  position: absolute;
  z-index: 10;
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
  background: #fff;
  display: inline-flex;
  align-items: center;
  right: 40px;
  border-radius: 5px;
  padding: 0px 67px 0px 0px;
  border: solid 1px #ff6b00;
  top: 95px;
  button {
    color: #ff6b00;
    font-size: 14px;
    font-family: $font-families-newjuneregular;
    position: absolute;
    right: 15px;
    bottom: 14px;
  }
  .right {
    border-left: solid 1px #ff6b00;
    padding-left: 12px;
    padding: 15px;
    text-align: center;
    i {
      color: #bcbcbc;
      position: absolute;
      right: 15px;
      top: 20px;
      cursor: pointer;
    }
  }
  img {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    margin-top: 8px;
  }
  .name {
    margin-bottom: 0px;
    margin-top: 4px;
    font-size: 12px;
  }
  .title {
    font-size: 12px;
    margin: 0px;
    padding-top: 8px;
  }
  .left {
    i {
      color: #bcbcbc;
      padding: 0 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}
.lib-table {
  max-width: 90%;
  margin: 0 auto;
  margin-bottom: 20px;
  .title-for-row {
    display: inline-flex;
    width: 100%;
    padding-right: 10px;
  }
}
.left-100 {
  padding-left: 100px;
}
.left-170px {
  padding-left: 170px;
}
.j-center {
  justify-content: center;
}
.please-grey-icon {
  color: rgba(0, 0, 0, 0.5) !important;
}
.select-error {
  color: #e1002c !important;
  font-size: 12px;
  margin-bottom: 10px;
}
.label-no-drag {
  font-size: 16px;
  color: #9b9b9b;
  margin: 0px;
}
.builder-modal-sm-btn {
  height: 40px !important;
  width: 250px !important;
  margin: 0 auto;
}
.give-me-builder-shadow {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  border-radius: 4px;
  margin-bottom: 8px;
}
.modal-builder-icons {
  display: inline-flex;
  justify-content: flex-end;
  .builder-icon-arrrow {
    width: 30px !important;
  }
}
.full-modal-c {
  min-width: 100% !important;
  min-height: 100% !important;
  background: #fff !important;
  border-radius: 0px !important;
}
.table-modal-s {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}
.no-md-errors-spacer {
  .md-errors-spacer {
    display: none;
  }
}
.search-c {
  position: relative;
  margin-right: 25px;
  .serach-icon {
    position: absolute;
    right: 0px;
    top: 9px;
    i {
      font-size: 20px;
      color: #bcbcbc;
    }
  }
  md-input-container:not(.md-input-has-value) input:not(:focus) {
    color: #000 !important;
  }
  md-input-container {
    margin: 0px;
    width: 150px;
    transition: 0.3s;
    padding-right: 20px;
    width: 200px;
    .md-errors-spacer {
      display: none;
    }
    input::placeholder {
      color: #adaeb4 !important;
    }
  }
}
.modal-select-btn {
  background: transparent;
  border: solid 1px #ff6b00;
  color: #ff6b00;
  display: inline-flex;
  align-items: center;
  height: 35px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  min-width: 110px;
  justify-content: center;
  font-size: 14px;
}
.modal-c-table {
  .title {
    color: #ff6b00;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    height: 20px;
    display: flex;
    line-height: 1em;
    align-items: center;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    cursor: pointer;
  }
  .right {
    width: 100%;
  }
  .text {
    font-size: 14px !important;
  }
}
.disable-me {
  border: 1px solid #adaeb4 !important;
  background-color: #f1f1f1 !important;
  color: #666666 !important;
  cursor: not-allowed;
}
.add-component-total-row {
  display: inline-flex;
  position: relative;
  width: 100%;
  align-items: center;
  height: 40px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  font-weight: 400 !important;
  font-size: 12px;
  color: #adaeb4;
  .center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
.empty-table-section {
  text-align: center;
  padding-top: 50px;
  padding-bottom: 20px;
}
.select-all-row {
  cursor: pointer;
  width: 100%;
  background-color: #ff6b00;
  height: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  i {
    margin-left: 10px;
  }
}
.rotate {
  &.active {
    transform: rotate(180deg);
  }
}
.need-orange-border {
  border: solid 1px #ff6b00 !important;
  border-radius: 3px;
}
.need-gray-border {
  border: solid 1px #a9a9a9 !important;
  border-radius: 3px;
}
.costume-del-btn {
  max-width: 250px;
  margin: 0 auto;
  height: 40px !important;
  font-size: 14px !important;
}
.left-10 {
  padding-left: 10px;
}
.bold {
  font-weight: 600;
}
.m_right_10 {
  margin-right: 10px;
}
.m_right_15 {
  margin-right: 15px;
}
.m_top_10 {
  margin-top: 10px;
}
.m_top_20 {
  margin-top: 20px;
}
.m_top_5 {
  margin-top: 5px;
}
.shadow-active {
  &.active {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
  }
}
.component-border-bottom {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
.grey-btn {
  background: #fff !important;
  color: #757575 !important;
  border: solid 1px #757575 !important;
  &:hover {
    background: #757575 !important;
    color: #fff !important;
  }
}
.arrow-down-scope {
  display: flex;
  height: 100%;
  align-items: center;
  color: #ff6b00;
  cursor: pointer;
  &.active {
    transform: rotate(180deg);
  }
}
.text {
  &-12 {
    font-size: 12px;
  }
  &-14 {
    font-size: 14px;
  }
  &-m-top-10 {
    margin-top: 10px;
  }
  &-m-bottom-10 {
    margin-bottom: 10px;
  }
}
.need-shadow {
  &.active {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
    .no-bd {
      border-bottom: none !important;
    }
  }
}
.dis-border {
  &.active {
    border-bottom: none;
  }
}
.need-border {
  border-top: solid 1px rgba(0, 0, 0, 0.12);
  border-radius: 5px !important;
}
.drop-icon-role {
  color: #adaeb4;
  font-size: 16px;
  &.active {
    transform: rotate(180deg);
    color: #757575;
  }
}
.grey {
  color: #adaeb4 !important;
}
.party-pdd {
}
.bold-14 {
  font-weight: 600;
  font-size: 14px !important;
}
//actionss
.pointer {
  cursor: pointer;
}
.f-style-builder {
  font-size: 12px !important;
  font-weight: 400;
}
.f-12 {
  font-size: 12px !important;
}
.opacity-0 {
  opacity: 0;
}
.roboto {
  color: #333333;
  font-size: 14px;
}
.center {
  text-align: center;
}
.scope-hightlight {
  padding: 20px 0;
  border: solid 1px transparent;
  border-radius: 5px;
  &.border-please {
    border: solid 1px #ff6b00;
  }
}
.inline-items-ratecard {
  width: 100%;
  .inline-items-inside {
    width: 100%;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 10px;
    max-height: 70px;
    overflow-y: auto;
    .inline-items-item {
      margin-bottom: 10px;
      border-radius: 5px;
      margin-right: 5px;
      background-color: #edeaea;
      padding: 7px 12px;
      font-size: 13px;
      display: inline-flex;
      align-items: center;
      line-height: 1em;
      span {
        margin-left: 10px;
      }
    }
  }
}

.builder-icons {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  .builder-icon {
    width: 35px;
    min-width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    .dlete-wrap {
      width: 25px;
      height: 25px;
      min-width: 25px;
      border: solid 1px;
      border-radius: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ff6b00;
      margin-right: 5px;
      cursor: pointer;
      i {
        font-size: 16px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}
.text-for-empty {
  display: inline-flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  align-items: center;
  width: 100%;
  min-height: 100px;
  justify-content: center;
  .text {
    font-size: 16px;
    font-weight: 600;
  }
  .undertext {
    color: #adaeb4;
    font-size: 14px;
  }
}
.flex-center-m {
  display: flex;
  justify-content: center;
}
//scrollbar
.costume-scroll::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}
.default-section {
  &.active {
    min-height: calc(100vh - 300px) !important;
  }
}
.center-text {
  text-align: center;
  font-size: 14px;
  font-weight: 600;
}
.left-text {
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}
.comment-icon {
  position: absolute;
  top: 20%;
  .absolute-number {
    font-size: 7px;
    position: absolute;
    top: 4.5px;
    left: 12px;
    color: #adaeb4;
  }
  i {
    font-size: 18px;
    margin-left: 5px;
    margin-bottom: 14px;
    color: #adaeb4;
  }
}
.add-colaborator-select {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  md-autocomplete-wrap {
    box-shadow: none;
    display: inline-flex;
    width: 100%;
  }
}
.zero-visible {
  opacity: 0 !important;
}
.erorr-m {
  font-size: 12px;
  line-height: 14px;
  overflow: hidden;
  -webkit-transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  transition: all 0.3s cubic-bezier(0.55, 0, 0.55, 0.2);
  opacity: 1;
  margin-top: 0;
  padding-top: 5px;
  transition-duration: 0.3s;
  transition-timing-function: linear;
  transition-property: all;
  opacity: 1;
  margin-top: 0px;
  color: #e1002c !important;
}
.coloborators {
  display: inline-flex;
  align-items: center;
  .colaborator {
    &:hover {
      border: solid 1px $primary-orange-p-500;
    }
    width: 40px;
    height: 40px;
    max-width: 40px;
    align-items: center;
    display: flex;
    border: solid 1px rgba(247, 246, 246, 0.62);
    border-radius: 20px;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    img {
      width: 100%;
      height: 100%;
      border-radius: 20px;
    }
  }
}
.quantity-input-m {
  height: 100%;
  width: 100%;
  border: none;
  padding: 0px;
  margin: 0px;
  font-size: 12px;
  color: #000;
  text-align: center;
  &:focus {
    outline: -webkit-focus-ring-color auto 0px;
  }
}
.text-wrapper {
  display: inline-block;
  width: auto;
  max-width: 380px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.addalias-input {
  display: inline-flex;
  height: 19px;
  align-items: center;
  input {
    height: 19px;
    width: 90px;
    border-right: 0px;
    border-left: 0px;
    border-top: 0px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    margin: 0px;
    padding: 0px;
    &:focus {
      outline: 0px;
    }
  }
  i {
    font-size: 16px;
    cursor: pointer;
    color: #ff6b00;
  }
}

.costume-scroll::-webkit-scrollbar {
  width: 12px;
  background-color: #adaeb4;
}

.costume-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #9b9b9b;
}

.d-contents {
  display: contents;
}

.ultra-right {
  justify-content: flex-end !important;
}
.ultra-dots {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px !important;
}
.ultra-grey {
  color: #adaeb4 !important;
}
.low-lines {
  .main-scope-row-wrap {
    height: 50px !important;
  }
  .main-scope-table-header {
    height: 50px;
  }
  .sortorder {
    font-size: 10px;
    margin-top: 2px;
  }
}
.ultra-orange {
  color: #ff6b00 !important;
}
.angular-ui-tree-paceholder {
  cursor: -webkit-grabbing !important;
}
.hand-cursor {
  cursor: pointer !important;
}
.nodrop-cursor {
  cursor: no-drop !important;
}
.hovered-table {
  .main-scope-row-wrap {
    &:hover {
      background: #f0f0f0;
    }
  }
  .md-label {
    margin: 0px;
  }
}
.builder-calendar {
  display: flex;
  align-items: center;
  min-width: 205px;
  .bold {
    margin-right: 5px;
  }
  md-input-container {
    margin: 0px;
  }
  .md-errors-spacer {
    display: none;
  }
  button.md-datepicker-button.md-icon-button.md-button.md-ink-ripple {
    display: none;
  }
  input {
    padding: 0px;
  }
  .md-datepicker-input-container.md-datepicker-invalid {
    margin-left: -2px;
  }
}
.inline-flex {
  display: inline-flex;
  .library-modal-input-wrap {
    width: 50%;
    .md-datepicker-input-container {
      width: 60%;
    }
  }
}
.colored-chart {
  justify-content: flex-start !important;
  .point {
    font-size: 14px;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background: #ffd7bb;
    border-radius: 7px;
    margin-right: 10px;
    margin-bottom: 10px;
    i {
      font-size: 12px;
      cursor: pointer;
      margin-left: 3px;
    }
    &.primary {
      background: #b6d7fa;
    }
  }
}
//blocks
.counter {
  display: inline-flex;
  border: solid 1px rgba(0, 0, 0, 0.12);
  border-right: none;
  .col {
    user-select: none;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: solid 1px rgba(0, 0, 0, 0.12);
    font-size: 12px;
    color: #adaeb4 !important;
    cursor: pointer;
    input {
      background: transparent;
    }
    &:nth-child(2) {
      color: #000;
    }
    input {
      background: #fff;
      width: 90%;
      height: 90%;
    }
  }
}
.library-folder {
  display: inline-flex;
  width: 100%;
  justify-content: space-between;
  height: 40px;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  .right {
    display: inline-flex;
    align-items: center;
  }
  .left {
    display: flex;
    align-items: center;
    i {
      color: rgb(2, 168, 243);
      margin-right: 10px;
    }
  }
  .builder-icon {
    width: 30px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.library-folder-inner {
  &.hidden {
    display: none;
  }
}
.search-wrapper {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  .search {
    position: relative;
    width: 200px;
    margin-bottom: 20px;
    z-index: 99999;
    .serach-icon {
      position: absolute;
      right: 0px;
      top: 9px;
      i {
        font-size: 20px;
        color: #bcbcbc;
      }
    }
    md-input-container:not(.md-input-has-value) input:not(:focus) {
      color: #000 !important;
    }
    md-input-container {
      margin: 0px;
      width: 100%;
      transition: 0.3s;
      padding-right: 20px;
      &.md-input-focused {
        width: 100%;
        transition: 0.3s;
      }
      .md-errors-spacer {
        display: none;
      }
      input::placeholder {
        color: #adaeb4 !important;
      }
    }
  }
}

.give-me-border {
  &.last {
    .main-scope-row-wrap {
      border-radius: 0px 0px 4px 4px;
    }
  }
}
.filter-button-m {
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(0, 0, 0, 0.8) !important;
  font-size: 14px;
  height: 30px !important;
  min-width: 100px !important;
  background: rgba(0, 0, 0, 0.1) !important;
  i {
    margin-left: 10px;
  }
}
.give-me-shadow {
  box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin-bottom: 8px;
}
.give-me-orange-border {
  border: solid 1px #ff6b00 !important;
  border-radius: 4px;
}
.pull-right-button {
  justify-content: flex-end;
}

.empty-folder {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  border-left: solid 1px rgba(0, 0, 0, 0.12);
  border-right: solid 1px rgba(0, 0, 0, 0.12);
  height: 125px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  p {
    &:first-child {
      font-weight: 600;
    }
    &:last-child {
      margin: 0px;
    }
  }
}
.need-rounded-border {
  &.give-me-shadow {
    border-radius: 4px;
  }
}
.padd-for-icon {
  padding-left: 17px;
}
.modal-box-header {
  background: linear-gradient(to right, rgba(47, 17, 57, 0.95), rgba(51, 56, 68, 0.95));
  padding: 60px;
  position: relative;
  border-radius: 10px 10px 0px 0px;
  @media (max-width: 1200px) {
    padding: 20px;
  }
  .modal-box__title {
    color: #fff;
    font-size: 26px;
    margin: 0px;
  }
  .modal-box__text {
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
    line-height: 1.15;
    margin-top: 20px;
    margin-bottom: 0px;
  }
}
.modal-box-body {
  padding: 50px;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  textarea {
    border-bottom: solid 1px rgba(0, 0, 0, 0.12);
    border-left: none;
    border-right: none;
    border-top: none;
    resize: none;
    &:focus {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
  textarea::-webkit-input-placeholder {
    font-size: 16px !important;
  }
}
.modal-box-btn-container {
  margin-top: 30px;
  a {
    color: #fff;
    background: #ee6400;
    border: solid 1px #ee6400;
    width: 100%;
    height: 50px;
    display: flex;
    border-radius: 25px;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    &:hover {
      background: transparent;
      color: #ee6400;
    }
  }
}
.flex-modal-box {
  padding: 0px !important;
  overflow: auto !important;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none !important;
  background-color: rgba(47, 17, 57, 0.3) !important;
}
.costume-button-export {
  background: #ff6b00;
  padding: 0px;
  height: 60px;
  border-radius: 30px;
  border: none !important;
  margin-bottom: 10px;
  .export-scope--format-selection__type {
    text-align: center;
    margin: 0 auto;
    padding-right: 50px;
    font-size: 14px;
  }
  .word-round {
    width: 40px;
    height: 40px;
    background: #fff;
    border-radius: 20px;
    color: #ff6b00;
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 12px;
    margin-left: 10px;
  }
}
.costume-li-ratecard {
  color: #000 !important;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12) !important;
  margin: 0px !important;
  input {
    color: #000 !important;
  }
  .inp-title {
    margin: 0px;
    font-size: 12px;
    color: #85888f;
  }
}
.max-h {
  max-height: 90vh;
  .ratecard-list {
    max-height: 220px;
    overflow: auto;
  }
}
.main-checkbox {
  margin: 0px;
  .md-icon {
    border-width: 1px;
  }
  .md-icon:after {
    border-color: #fff !important;
    left: 6.66667px !important;
  }
}
.modal-preloader {
  position: absolute;
  background: #fff;
  width: 100%;
  left: 0px;
  top: 0px;
  height: 100%;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
}
.relative {
  position: relative;
}
.del-ttem-modal {
  min-width: 80% !important;
  min-height: 80% !important;
  max-height: 80% !important;
  overflow: hidden;
  .library-modal__header {
    height: 20vh;
    padding-top: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .deliverable-builder-container {
    height: 60vh;
  }
  .left-menu-tabs {
    max-height: calc(60vh - 60px) !important;
  }
}
.statistic-loader {
  left: 0px;
  margin-top: 120px;
  z-index: 10;
}
.stat-header {
  width: calc(100% - 20px) !important;
  margin: 0 auto;
}
.stat-loader-main {
  height: 100%;
  padding-top: 100px;
  justify-content: center;
  left: 0px;
  position: absolute;
  z-index: 9999 !important;
  background: #fff;
  width: 100%;
}
.empty-statistic {
  position: absolute;
  background: #fff;
  display: flex;
  align-items: center;
  width: 100%;
  left: 0px;
  top: 40px;
  border-radius: 5px;
  justify-content: center;
  height: 330px;
  flex-direction: column;
  z-index: 1;
  p {
    &:first-child {
      font-size: 16px;
      font-weight: 600;
      margin: 0px;
    }
    &:last-child {
      font-size: 12px;
      color: #adaeb4 !important;
    }
  }
}
.amcharts-chart-div {
  a {
    font-size: 11px !important;
  }
}
.first-graph {
  .amcharts-chart-div {
    a {
      left: 0px !important;
    }
  }
}

.error-display {
  background: $semantic-error-500 !important;
  &:hover {
    color: white !important;
  }
}
.edit-ratecard-name {
  background-color: white;
  padding: 0px;
  font-size: 18px;
  height: 20px;
  i {
    font-size: 16px;
    margin-left: 4px;
    margin-right: 1px;
    color: #ff6b00;
    margin-top: 2px;
  }
}
.hidden-overflow {
  overflow: hidden;
}
.ratecard-name-input {
  margin: 0px;
  padding: 0px;
  .md-input {
    width: 100%;
    max-width: 170px;
    margin-bottom: 15px;
  }
  .md-errors-spacer {
    min-height: auto;
  }
}
.cancel-ratecard-name-input {
  max-width: 170px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.edit-profile-name {
  background-color: white;
  padding: 0px;
  font-size: 18px;
  height: 20px;
  i {
    font-size: 16px;
    margin-left: 4px;
    margin-right: 1px;
    color: #ff6b00;
    margin-top: 2px;
  }
}
.profile-name-input {
  margin: 0px;
  padding: 0px;
  width: 300px;
  .md-input {
    width: 100%;
    max-width: 300px;
  }
  .md-errors-spacer {
    min-height: auto;
  }
}
.profile-name-placeholder {
  display: inline;
}
.cancel-profile-name-input {
  max-width: 170px;
  width: 100%;
  justify-content: flex-end;
  display: flex;
}

.responsive-non-scrolling-modal {
  @media (max-width: 1441px) {
    .manage-modal__body {
      padding: 25px 60px;
    }
    .manage-modal__header {
      padding: 30px 60px;
    }
    .manage-modal-input-wrap {
      margin-bottom: 15px !important;
    }
    .manage-modal-button {
      padding-top: 10px !important;
    }
  }
}
.change-email {
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  .text {
    font-size: 12px;
    color: #85888f;
    line-height: 19px;
  }
  i.option {
    float: right;
    font-size: 16px;
    width: 25px;
    height: 25px;
    border-radius: 50px;
    border: solid 1px #ff6b00;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 25px;
    cursor: pointer;
    &:focus {
      outline: -webkit-focus-ring-color auto 0px;
    }
  }
}
.inline-responsive-list {
  display: inline-flex;
  width: 100%;
}

.edit-notification-profile-name {
  background-color: white;
  padding: 0px;
  font-size: 18px;
  height: 20px;
}
.edit-notification-profile-name i {
  font-size: 24px;
  margin-left: 4px;
  margin-right: 1px;
  color: #ff6b00;
  margin-top: 2px;
}
.notification-profile-name-input {
  margin: 20px 0 0px 0;
  font-size: 32px;
  padding: 0px;
  width: 250px;
}
.notification-profile-name-input input {
  height: 40px;
}
.sow_budgets_panel {
  color: #ff6b00;
  li {
    font-size: 12px !important;
    color: #000 !important;
  }
}

.filter-tag {
  display: flex;
  padding: 4px 8px;
  align-items: center;
  gap: 8px;
  border-radius: 2px;
  background: var(--primary-blue-violet-200, #dbe3f6);
  font: $product-ui-text-m;
  color: $primary-blue-violet-800;
  margin: 8px 16px 8px 0;
  max-width: 300px;
  height: 24px;

  .tag-value {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    flex: 1 0 0;
    overflow: hidden;
    font-variant-numeric: lining-nums proportional-nums;
    text-overflow: ellipsis;
    font: $product-ui-text-bold-m;
    word-break: break-all;
  }

  mat-icon {
    height: 16px;
    width: 16px;
    font-size: 16px;
    cursor: pointer;
  }
}

.clear-all-tag {
  font: $product-ui-text-bold-m;
  height: 24px !important;
  margin-top: 8px;

  mat-icon {
    height: 24px !important;
    width: 24px !important;
    font-size: 24px !important;
  }
}

.filtered-count {
  font: $product-heading-bold-h1;
  color: $primary-blue-violet-p-900;
  padding-top: 6px;
}
