$black: rgba(0, 0, 0, 0.87);

.sub-header-container {
    display: flex;
    background: #fff;
    height: 50px;
    align-items: center;
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.18), 0 0px 15px 0 rgba(0, 0, 0, 0.15);
    position: relative;
    .breadcrumbs {
        margin-left: 30px;

        a {
            color: $primary-orange-p-500;
            &.active {
                color: $black;
            }
            &::after {
                content: ' > '
            }
            &:last-child:after {
                content: ''
            }

            &:hover {
                color: $primary-orange-p-500;
                cursor: pointer;
                &::after {
                    content: ' < '
                }
                &:last-child:after {
                    content: ''
                }
            }
        }
    }
}
