.action-btn-container {
    a {
      background: #ff6b00;
      border: solid 1px #ff6b00;
      display: inline-flex;
      align-items: center;
      height: 40px;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 20px;
      min-width: 150px;
      justify-content: center;
      &:focus {
        outline: none;
      }
      &:hover {
        background: #fff;
        color: #ff6b00;
        border: solid 1px #ff6b00;
      }
      &.transparent {
        background: transparent;
        display: inline-flex;
        align-items: center;
        height: 40px;
        padding-left: 20px;
        padding-right: 20px;
        border-radius: 20px;
        min-width: 150px;
        border: solid 1px #ff6b00;
        justify-content: center;
        color: #ff6b00;
        &:hover {
          color: #fff;
          background: #ff6b00;
          border: solid 1px #ff6b00;
          transition: 0.3s ease-in;
        }
      }
      &.grey-tone {
        background: #fef6ff;
        border: solid 1px #adadad !important;
        color: #adadad;
      }
      span {
        display: flex;
        align-items: center;
        i {
          font-size: 15px;
          margin-right: 5px;
        }
      }
      &.thin {
        padding: 11px;
        font-size: 14px;
        height: 30px !important;
        min-width: 100px !important;
      }
    }
  }

  .btn {
    background: getBtnColor("btn-bg");
    border: $main-border solid transparent;
    color: #fff;
    font-family: $font-family;
    font-weight: bold;
    text-align: center;
    display: block;
    vertical-align: bottom;

    @include rem(border-radius, 2px);
    @include rem(margin, 5px 0);

    &__row {
      @include rem(margin, 0 0 10px);
    }

    &--small {
      @include fontSize(btnSmall);
      @include rem(padding, 8px 12px);
    }
    &--medium {
      @include fontSize(btnMedium);
      @include rem(padding, 10px 20px);
    }
    &--large {
      @include fontSize(btnLarge);
      @include rem(padding, 14px 50px);
    }
    &--img {
      @include rem(height, 42px);
      overflow: hidden;
      img {
        @include responsiveImage();
      }
    }
    &--fab {
      @include rem(width, 56px);
      @include rem(height, 56px);
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
      border-radius: 50%;
      background-clip: padding-box;
      overflow: hidden;
      vertical-align: middle;
      display: table-cell;
      .icon {
        vertical-align: middle;
      }
    }
    &--full {
      width: 100%;
    }
    &--purple {
      background: getBtnColor("btn-bg--purple")
    }
    &--pressed {
      background: getBtnColor("btn-bg--pressed") !important;
      color: #fff;
    }
    &--outline {
      background: transparent;
      color: #666972;
      border: $main-border solid #666972;
    }
    &--outline:hover {
      background-color: rgba(158, 158, 158, 0.2);
    }

    &--outline-action {
      background: transparent;
      color: $primary-orange-p-500;
      border: $main-border solid $primary-orange-p-500;
      &:hover {
        background-color: rgba(158, 158, 158, 0.2);
      }
      &.outline-grey {
        color: #666972;
        border: $main-border solid #666972;
      }
    }

    &--inline {
      display: inline-block;
      @include rem(margin, 5px 1px 3px 0px);
      &-no-margin {
        display: inline-block;
        @include rem(margin, 0);
      }
    }
    &--action {
      background: $primary-orange-p-500;
      &:hover {
        color: #fff;
        background: getHeroColor("hero-orange-2");
        @include transition(all 0.3s);
      }
    }
    &--blue {
      background-color: #1E88E5 !important;
      &:hover {
        background-color: #1976D2 !important;
      }
    }
    &--red {
      background-color: #C62828 !important;
      &:hover {
        background-color: #B71C1C !important;
      }
    }
    &--cancel {
      font-family: $font-family;
      background: transparent;
      color: #000;
      &:hover {
        color: #000 !important;
        background: transparent !important;
        opacity: .8;
        @include transition(all 0.3s);
      }
    }
    &--focused {
      border: $main-border solid $primary-orange-p-500;
    }
    &--disabled, &.disabled, &[disabled='disabled'], &[disabled] {
      background: getBtnColor("btn-bg--disabled");
      cursor: default;
      &:hover:not(.disabled) {
        color: #fff; // Overriding all colours
        background: getBtnColor("btn-bg--disabled");
      }
    }
    &--absolute {
      position: absolute;
      z-index: $above-content;
      top: 0;
      right: 0;
    }
    &--transparent {
      background: transparent;
      &:hover {
        color: #fff; // Overriding all colours
        opacity: .8;
        background: getBtnColor("btn-bg--pressed");
        @include transition(all 0.3s);
      }
    }
    &--rounded {
      border-radius: 20px;
    }
    &__img {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 5px;

      > img {
        max-width: 100%;
        height: auto;
        border-radius: 4px;
      }
    }
    &--chevron {
      display: flex;
      @include rem(padding, 2px);
      @include rem(margin-top, 15px);
      .material-icons {
        font-size: 32px;
      }
    }
    &--link:hover {
      color: $primary-orange-p-500 !important;
      cursor: pointer;
    }
  }

  .scope-button {
    margin-left: 15px;
    &.no-left-margin {
      margin-left: 0;
    }
    &.with-icon {
      a, button {
        span {
          display: flex;
          align-items: center;
          i {
            margin-right: 5px;
          }
        }
      }
    }
    &.groups-table-row {
      margin-left: 0;
    }
    &.absolute-button {
      margin-left: 0px;
      position: absolute;
      left: 0px;
      top: 0px;
      transition: .4s;
      z-index: 16;
      &.active {
        left: calc(20% + 20px);
        transition: .4s;
        i {
          transform: rotate(180deg);
        }
        a, button {
          background: #ff6b00;
          color: #fff;

        }
      }
      a, button {
        border: solid 1px #ff6b00;
        background: transparent;
        color: #ff6b00;
        min-width: 135px;
        &:hover {
          background: #ff6b00;
          color: #fff;
        }
      }
    }
    &.transparent {
      a, button {
        color: #85888F !important;
        background: transparent !important;
        border: solid 1px #85888F !important;
        font-weight: 600;
        &:hover {
          background: #85888F !important;
          color: #fff !important;
        }
      }
    }
    a, button {
      height: 40px;
      background: #ff6b00;
      border: solid 1px #ff6b00;
      display: inline-flex;
      align-items: center;
      padding-left: 20px;
      padding-right: 20px;
      border-radius: 20px;
      font-size: 16px;
      min-width: 120px;
      justify-content: center;
      &:hover {
        background: transparent;
        color: #ff6b00;
      }
      i {
        font-size: 15px;
      }
    }
    &.disabled a, &.disabled button {
      background: getBtnColor("btn-bg--disabled");
      cursor: default;
      border: solid 1px getBtnColor("btn-bg--disabled");
      &:hover:not(.disabled) {
        color: #fff; // Overriding all colours
        background: getBtnColor("btn-bg--disabled");
      }
      &.orange {
        background-color: $primary-orange-p-500;
        border-color: $primary-orange-p-500;
        color: white;
        &:hover:not(.disabled) {
          color: #fff; // Overriding all colours
          background: $primary-orange-p-500;
        }
      }
    }
    &.center-button {
      text-align: center;
      padding-top: 30px;
    }
    &.small {
      a, button {
        height: 30px;
        white-space: pre;
        min-width: auto;
      }
    }
  }

  @include breakpoint(tablet) {

  }

  @include breakpoint(desktop) {

  }

.scope-actions-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  cursor: pointer;
  background-color: #FFF0E5;
  width: 32px;
  height: 32px;
  margin-left: 8px;

  mat-icon {
    color: #D65A00;
  }

  &.cursor-default {
    cursor: default;
  }

  &:hover {
    border: 2px solid rgba(214, 90, 0, 0.42);
  }
}

.browse-btn {
  height: 40px;
  background: transparent;
  display: inline-flex;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  font-size: 16px;
  min-width: 120px;
  justify-content: center;
  color: #85888F;
  border: solid 1px #85888F;
  cursor: pointer;
  &:hover {
    background: #85888F;
    color: #fff;
  }
}

button.mat-mdc-button.scope-ui-modal-flat-button {
  color: $secondary-neutral-black-white;
  background: $primary-orange-p-500;
  width: 100%;
  height: 50px;
  display: flex;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: $font-families-newjuneregular;
  margin-left: 0 !important;
}

button.mat-mdc-button.scope-ui-modal-flat-button:disabled {
  background: $primary-blue-violet-100;
  color: $primary-blue-violet-600;
  border: none;
  box-shadow: none;
}

button.scope-ui-modal-button {
  display: inline-flex;
  height: 32px;
  padding: 0 16px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 200px;
  background: transparent;
  color: $primary-blue-violet-p-900;
  font: $product-heading-bold-h6;

  &.confirm {
    background: $primary-orange-p-500;
    color: $secondary-neutral-black-white;

    &:disabled {
      background: $primary-blue-violet-100;
      color: $primary-blue-violet-600;
    }
  }
}

.custom-del-button {
  border-top: 1px solid $secondary-neutral-black-300;
  width: 100%;
  align-items: center;
}

button.action-button {
  height: 32px !important;
  padding: 0px 16px;
  align-items: center;
  justify-content: center;
  border-radius: 200px;
  background: $primary-orange-p-500;
  padding-top: 1px;

  &[aria-expanded="true"] {
    background: $primary-orange-600;
  }

  &:active {
    background: $primary-orange-700;

    .mat-mdc-button-persistent-ripple::before {
      opacity: 0;
    }
  }

  & span.mdc-button__label {
    font-family: $font-families-newjunebold;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
    letter-spacing: 0;
  }

  &:disabled {
    cursor: default;
    background: #eaeef9;
    color: #757d90;

    span.mdc-button__label {
      color: #757d90;
    }
  }

  &:not([disabled]) span.mdc-button__label {
    color: $secondary-neutral-black-white;
  }
}

.mat-mdc-button .mdc-button__label + .mat-icon {
  margin-left: 4px;
}

.mat-mdc-button > .mat-icon {
  height: 16px;
  width: 16px;
  color: $secondary-neutral-black-white;
}
