@import 'styles/base/variables';

.main-nav {
  display: flex;
  align-items: center;
  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    li {
      margin-right: 32px;
      color: $primary-orange-p-500;
      font-variant-numeric: lining-nums proportional-nums;
      font-family: $font-families-newjunebold;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 130%;
      a {
        padding-bottom: 7px;
        white-space: nowrap;
        &.active {
          border-bottom: solid 2px $primary-orange-p-500;
          color: $primary-orange-p-500;

          &:hover {
            color: $primary-orange-p-500;
            border-bottom: solid 2px $primary-orange-p-500;
          }
        }
        &:hover {
          border-bottom: solid 2px $secondary-neutral-black-white;
          color: $secondary-neutral-black-white;
        }
      }
    }
  }
}
.no-scrolling {
  height: auto;
  overflow-y: hidden;
}
