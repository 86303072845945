// Responsive grid


// 12 Columns Grid
.column--responsive,
.columns--responsive {
    margin-bottom: 20px;
    padding: 0;
    @include span-columns(12);
    @include clearfix;
    &:last-child {
        margin-right: 0;
    }
}

// Grid classes
.one--column--responsive {
    @extend .column--responsive;
    @include media($tablet) {
        @include span-columns(1);
    }
}
.two--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(2);
    }
}
.three--columns--responsive {
    @extend .columns--responsive;
    // @include media($mobile) {
    //     @include span-columns(6);
    // }
    @include media($tablet) {
        @include span-columns(3);
    }
    &--auto-row {
        @extend .three--columns--responsive;
        // @include media($mobile) {
        //    @include omega(2n);
        // }  
        @include media($tablet) {
           @include omega(4n);
        }  
    }
}
.four--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(4);
    }
}
.five--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(5);
    }
}
.six--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(6);
    }
}
.seven--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(7);
    }
}
.eight--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(8);
    }
}
.nine--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(9);
    }
}
.ten--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(10);
    }
}
.eleven--columns--responsive {
    @extend .columns--responsive;
    @include media($tablet) {
        @include span-columns(11);
    }
}
.twelve--columns--responsive {
    @extend .columns--responsive;
    float: none;
    max-width: 1000px;
    @include pad(15px);
    margin: 0 auto;
    &:last-child {
        margin: 0 auto;
    }
}
