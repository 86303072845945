.scope-pod {
	background:#fff;
	border-radius:$border-radius;
    margin: 0 auto !important;
	@include clearfix;
	@include l-constrained;
	@include rem(padding, 0px 40px 30px);
	@include rem(margin-bottom, 25px);
	@include rem(padding-top, 10px);

	&__title{
		border-bottom: 2px solid getGreytone('greytone-1');
		@include rem(padding-bottom, 10px);
		@include fontSize(smallSubTitle);
		&--no-border {
			border-bottom: none;
		}
		&--no-margin {
			@include rem(margin, 0);
		}
	}
	&__list {
		@include list-reset;
		@include rem(padding-top, 30px);
	}
	&__tile{
		border-radius:$border-radius;
		box-sizing: content-box;
		border: 1px solid getGreytone('greytone-5');
		@include rem(padding, 20px);
		@include clearfix;

		&.active {
			border: 1px solid getGreytone('greytone-1');
		}

		// Agency members section
		&__member {
			@include rem(padding-left, 10px);
			h5, h6 {
				@include overflow-ellipsis();
			}
		}
		&__info {
			@include clearfix;

            &__header {
                display: inline-block;
                width: 100% !important;
								max-width: 100% !important;

                // SecondParties Section
                &__client-name {
										text-overflow: ellipsis;
										overflow: hidden;
                    margin-top: 0;
                    font-family: $font-family;
                    font-weight: bold;
                    float: left;
                    margin-top: 5px;
                    max-width: 65%;
                    @include fontSize(listMedium);

                    &.traffic-imported {
                      max-width: 40%;
                    }
                }

                button.delete {
                    float: right;
                    margin-right: 0;
                    padding-right: 0;
                    padding-top: 0;

                    .material-icons {
                      border: 1px solid;
                      border-radius: 50%;
                      color: #85888F;
                      font-size: 18px;
                    }
                }
            }
        }
		&__system {
			// @extend .page-header__system;
			float: left;
		}
		&__name{
			@include fontSize(listMedium);
			@include rem(margin-bottom, 5px);
		}
		&__title{
			margin-top:0;
			@include fontSize(listSmall);
		}

		&__rate-card-default {
			font-weight: 100;
			@include fontSize(listSmall);
		}
		&__rate-card {
			font-weight: 100;
			@include fontSize(listSmall);
            margin-top: 5px;
            float: right;
            margin-bottom: 0;
		}
		&__list {

			@include list-reset;

			li {
				@include rem(margin, 15px 0);
				@include fontSize(listSmall);

				a {
					color: getGreytone('greytone-1');
				}

				ul {
					@include list-reset;
					li {
						float: left;
						&:last-child {
							float:right;
						}
						a {
							color: $primary-orange-p-500;
						}
					}
				}
			}
		}
		&--disabled {
			.scope-pod__tile__info, md-input-container {
				opacity: 0.4;
			}
		}
	}
	&__form {
		@include rem(margin-top, 50px);
		&__system {
			@include list-reset;
			margin-top: 5px;
			li {
				@include fontSize(listXsmall);
				&:first-child {
					float:left;
				}
				&:last-child {
					float:right;

					span {
						display: inline-block;
					}
				}
				a {
					color: black;
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}
	}
	&__text {
		@include rem(margin-top, 50px);
		p {
			@include fontSize(paragraphSmall);
		}
		a {
			color: getGreytone('greytone-1');
			border-bottom: $thick-border solid getGreytone('greytone-1');
			&:hover {
				color:#000;
			}
		}
	}

	&__agencies-list {
		// @extend .dynamic-list;
		position: inherit;

		li {
			position: inherit;
			border-top: $main-border solid getGreytone('greytone-6');
			border-bottom: none;
			@include rem(padding, 20px 0 18px);
			@include fontSize(listSmall);

			&:last-child {
				border-bottom: $main-border solid getGreytone('greytone-6');
			}

			md-input-container {
				margin: -10px 0 0 0;
				md-select-value {
					font-size: inherit;
				}
			}
			md-chip {
				@include fontSize(listSmall);
			}
			.icons {
				a {
					color: #000;
				    border: 1px solid #000;
				    border-radius: 100%;
				    display: inline-block;
				    height: 26px;
				    width: 26px;
				}
			}
		}

		.btn {
			margin-right: 0;
			@include rem(top, 7px);
			@include rem(width, 140px);
		}
	}

	md-input-container {
		width: 100%;
	}
}
