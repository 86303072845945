// Variables

// Colour Scheme
// =============
// To avoid confusion with CSS properties,
// colour scheme variable names use american english _color

// Main colour palette
// $color-primary: #1f9964;
// $color-secondary: #47b77a;
// $color-tertiary: #209864;

// $color-highlight: #ff9600;
// $color-muted: #eef;
// $color-note: #666;

// // Common colours
// $color-text: #209864;
// $color-text-alt: #fff;
// $color-background: #fff;
// $color-border: #ddd;

// Links
$color-link-default: #fff;
$color-link-visited: #fff;
$color-link-hover:getGreytone("greytone-1");
$color-link-active: #fff;

//Z-indexes
$below-content: 1;
$content: 2;
$above-content: 3;
$snackbar-content: 4;
$header-content: 5;
$tooltip-content: 6;
$modal-content: 7;


//Border Radius
$border-radius: 5px;

// Line-heights
$line-height-medium: 1.9;
$line-height-small: 1.5;
$line-height-x-small: 1.2;

// Font Stacks
// ===========
$font-family:  NewJune, Verdana, Geneva, sans-serif;
$font-family-default: $font-family;

// Font Weights
// ============

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-semibold: 700;
$font-weight-bold: 900;

// Borders
$main-border: 1px;
$thick-border:2px;

//============================================================
// Media queries thresholds
//============================================================

$threshold-minWidth:    320px;
$threshold-mobileBig:   480px;
$threshold-phablet:     600px;
$threshold-tablet:      768px;
$threshold-desktop:     960px;
$threshold-desktopBig: 1200px;

//================================================================
// Neat: Breakpoints
//================================================================

$mobile: new-breakpoint(max-width 480px);
$mobileBig: new-breakpoint(min-width 600px);
$tablet: new-breakpoint(min-width 768px);
$desktop: new-breakpoint(min-width 1024px);

