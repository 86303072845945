//==============================================================================
// General - Grids and layouts.
//==============================================================================

$scopeGrid: new-breakpoint(min-width 320px 12);

// Container for body elements for uniform padding.
.container {
    padding: 0;
}

// 12 Columns Grid
.column,
.columns {
    padding: 0;
    min-height: 1px;
    @include span-columns(12);
    @include clearfix;
    &:last-of-type {
        @include omega;
    }
    span, .comment-subject {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      white-space: normal;
    }
}

// Grid classes
.one--column, .columns-1 {
    @extend .column;
    @include media($scopeGrid) {
        @include span-columns(1);
    }
}

.two--columns, .columns-2 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(2);
    }
}

.three--columns, .columns-3 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(3);
    }
}

.four--columns, .columns-4 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(4);
    }
}

.five--columns, .columns-5 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(5);
    }
}

.six--columns, .columns-6 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(6);
    }
}

.seven--columns, .columns-7 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(7);
    }
}

.eight--columns, .columns-8 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(8);
    }
}

.nine--columns, .columns-9 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(9);
    }
}

.ten--columns, .columns-10 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(10);
    }
}

.eleven--columns, .columns-11 {
    @extend .columns;
    @include media($scopeGrid) {
        @include span-columns(11);
    }
}

.twelve--columns, .columns-12 {
    @extend .columns;
    float: none;
    max-width: 1000px;
    @include pad(15px);
    margin: 0 auto;
    &:last-child {
        margin: 0 auto;
    }
}


.mobile--only {
    display: block;
    @include media($tablet) {
        display: none;
    }
}

.mobile--hidden {
    display: none;
    @include media($tablet) {
        display: block;
    }
}


.full-column {
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
}

.scrollable_tab {
    height: calc(100vh - 130px);
    overflow-y: auto;
}
