/** Relates to Invision Designs:
	1 will be darkest colour in
	in following colour maps unless specified.
	Using US English colors in definitions
**/

//text-primary
$text-colors: (
  'text-primary': $primary-blue-violet-p-900,
  'text-white': #ffffff,
);

@function getTextColor($name) {
  @if not map-has-key($text-colors, $name) {
    @error 'Unknown color: ' + $name;
  }
  @return map-get($text-colors, $name);
}

// Hero Colors
$hero-colors: (
  'hero-grey-1': $primary-blue-violet-p-900,
  'hero-grey-2': #262b35,
  'hero-orange-1': #ff6b00,
  'hero-orange-2': #ee6400,
  'hero-light-teal-1': #84ccce,
  'hero-light-teal-2': #84cccee0,
  'hero-light-purple-1': #331d5d,
  'hero-light-blue-2': #7e36b5,
  'hero-purple': #2f1139,
  'hero-light-purple': #48285b,
);

@function getHeroColor($name) {
  @if not map-has-key($hero-colors, $name) {
    @error 'Unknown color: ' + $name;
  }
  @return map-get($hero-colors, $name);
}

// Greytone Colours
$greytone-colors: (
  'greytone-1': #474b56,
  'greytone-2': #5c6069,
  'greytone-3': #85888f,
  'greytone-4': #adaeb4,
  'greytone-5': #b6b7bc,
  'greytone-6': #cecfd1,
  'greytone-7': #e4e5e5,
  'greytone-8': #efefef,
  'greytone-9': #f7f7f7,
  'greytone-10': #ffffff,
);

@function getGreytone($name) {
  @if not map-has-key($greytone-colors, $name) {
    @error 'Unknown color.';
  }
  @return map-get($greytone-colors, $name);
}

@function getGreytoneByIndex($index) {
  @if not map-has-key($greytone-colors, 'greytone-' + $index) {
    @error 'Unknown color.';
  }
  @return map-get($greytone-colors, 'greytone-' + $index);
}

/** User Colours
	Set from left to right from
	Invision colour tiles
**/
$user-colors: (
  'user-1': #fbd9e0,
  'user-2': #fbd9e0,
  'user-3': #c4f0ea,
  'user-4': #fdf3d3,
  'user-5': #d5f0c6,
);

@function getUserColor($name) {
  @if not map-has-key($user-colors, $name) {
    @error 'Unknown color.';
  }
  @return map-get($user-colors, $name);
}

/** System Colours
	Set from left to right from
	Invision colour tiles
**/
$system-colors: (
  'red-light': #fbd9e0,
  'teal_light': #c4f0ea,
  'amber-light': #fdf3d3,
  'green-light': #d5f0c6,
  'red': #e1002c,
  'teal': #12c1a8,
  'amber': #f5b323,
  'green': #54c01a,
  'red-dark': #c91323,
  'teal-dark': #0b818c,
  'amber-dark': #d09d0d,
  'green-dark': #439a15,
);

@function getSystemColor($name) {
  @if not map-has-key($system-colors, $name) {
    @error 'Unknown color.';
  }
  @return map-get($system-colors, $name);
}

// Buttons Colors
$btn-colors: (
  'btn-bg': $primary-blue-violet-p-900,
  'btn-bg--purple': #2f1139,
  'btn-bg--disabled': #999ca2,
  'btn-bg--pressed': #5c6069,
  'btn-bg--focused': $primary-blue-violet-p-900,
);

@function getBtnColor($name) {
  @if not map-has-key($btn-colors, $name) {
    @error 'Unknown color.';
  }
  @return map-get($btn-colors, $name);
}

// Warnings
$warning--success: #d5f0c6;
$warning--attention: #c4f0ea;
$warning--check: #fdf3d3;
$warning--broken: #fbd9e0;

// Status Colors
$status-colors: (
  'status-red': #ea4f6d,
  'status-amber': #f5a623,
  'status-green': #7ed321,
  'status-grey': #565866,
  'status-black': #000000,
  'status-blue': #008be5,
);

@function getStatusColor($name) {
  @if not map-has-key($status-colors, $name) {
    @error 'Unknown color.';
  }
  @return map-get($status-colors, $name);
}

.text-error-600 {
  color: $semantic-error-p-600 !important;
}

.text-error-800 {
  color: $semantic-error-800 !important;
}

.text-critical-600 {
  color: $semantic-critical-600 !important;
}

.text-critical-700 {
  color: $semantic-critical-700 !important;
}

.text-success-500 {
  color: $semantic-success-500 !important;
}

.text-success-700 {
  color: $semantic-success-700 !important;
}

.text-success-800 {
  color: $semantic-success-p-800 !important;
}

.text-safe-700 {
  color: $semantic-safe-700 !important;
}

.text-safe-900 {
  color: $semantic-safe-900 !important;
}

.text-blue-violet-500 {
  color: $primary-blue-violet-500 !important;
}
